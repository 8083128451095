import { useEffect, useMemo, useState } from 'react';
import { Icon, Modal as ModalCTC } from 'ctc-component-library';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/store';
import {
  deleteGroupStudent,
  listAvailableMoodleRooms
} from '../../../../slices/groupSlice/groupByIdSlice';
import { useModal } from '../../../../hooks/useModal';
import Modal from '../../../../components/Modal/Modal';
import colors from '../../../../components/atoms/Colors';
import Table from '../../../../components/molecules/Table';
import Tooltip from '../../../../components/atoms/Tooltip/Tooltip';
import UpdateStudentRoom from './UpdateStudentRoom/UpdateStudentRoom';
import { AppDispatch, useAppDispatch } from '../../../../store/store';
import ButtonFilled from '../../../../components/molecules/ButtonFilled';
import ModalEditProjectId from './ModalEditProjectId/ModalEditProjectId';
import FloatingMenu from '../../../../components/molecules/FloatingMenu';
import { IRoom, IStudent } from '../../../../interfaces/group.interface';
import useComplementStudents from '../../../../hooks/useComplementStudents';
import ItemFloatingMenu from '../../../../components/molecules/ItemFloatingMenu';
import { listRoomsByGroupId } from '../../../../slices/roomSlice/roomsByGroupIdSlice';
import AddStudentToGroup from './ModalAddStudentToGroup/AddStudentToGroup/AddStudentToGroup';
import ButtonLineal from '../../../../components/molecules/ButtonLineal';
import GuardianDetails from '../../../Guardians/ModalGuardianDetails/GuardianDetails/GuardianDetails';
import Typography from '../../../../components/atoms/Typography/Typography';
import ModalMessageAlert from '../../../../components/molecules/ModalMessageAlert';
import { ChangeRoomModal } from './ChangeRoomModal/ChangeRoomModal';
import { AlertSnackbar } from '../../../../components/common/AlertSnackbar/AlertSnackbar';
import { patchTransferStudentToRoom } from '../../../../services/students';

interface Props {
  setRefetchListRoomsByGroupId: any;
  students: IStudent[];
  rooms: IRoom[];
  groupId: number;
  isFetching: boolean;
  setActiveTabLcl: any;
}

const Students: React.FC<Props> = ({
  students,
  rooms,
  groupId,
  isFetching,
  setActiveTabLcl,
  setRefetchListRoomsByGroupId
}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch: AppDispatch = useAppDispatch();
  const [idTutor, setIdTutor] = useState<null | number>();
  const [idStudent, setIdStudent] = useState<null | number>();
  const [idStudentToMove, setIdStudentToMove] = useState<null | number>();
  const [nameStudentToMove, setNameStudentToMove] = useState<string>('');
  const [idCurrentRoom, setIdCurrentRoom] = useState<null | number>();
  const [isLoadingDeleteGroup, setIsLoadingDeleteGroup] =
    useState<boolean>(false);

  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [isSuccessAlert, setIsSuccessAlert] = useState<boolean>(false);

  const [availableMoodleRooms, setAvailableMoodleRooms] = useState<any>([]);

  const {
    openModal: openModalAddStudentToGroup,
    closeModal: closeModalAddStudentToGroup,
    isOpenModal: isOpenModalAddStudentToGroup
  } = useModal();

  const {
    openModal: openModalDetailTutor,
    closeModal: closeModalDetailTutor,
    isOpenModal: isOpenModalDetailTutor
  } = useModal();

  const {
    openModal: openModalDeleteStudent,
    closeModal: closeModalDeleteStudent,
    isOpenModal: isOpenModalDeleteStudent
  } = useModal();

  const {
    openModal: openModalChangeRoom,
    closeModal: closeModalChangeRoom,
    isOpenModal: isOpenModalChangeRoom
  } = useModal();

  const { data: complementStudents } = useComplementStudents({
    groupId,
    openModal: isOpenModalAddStudentToGroup
  });

  const cellProjectId = {
    display: 'flex',
    gap: 40
  };

  const getUserToShowOption = () => {
    const userMailId: any = {
      sstorni: 6639,
      fkaiser: 9598,
      jpanelati: 10645,
      jmarquez: 10294,
      wrodriguez: 10400,
      mvelasquez: 4733,
      ecoronado: 6621
    };

    return Object.values(userMailId).includes(user?.id);
  };

  const serializePayload = (payload: { id: number; name: string }[]) => {
    return payload.map(({ id, name }) => ({
      id: id,
      label: name,
      value: name
    }));
  };

  const handleDeleteGroupStudent = async (
    groupId: number,
    studentId: number
  ) => {
    try {
      setIsLoadingDeleteGroup(true);
      await dispatch(deleteGroupStudent({ groupId, studentId }));
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingDeleteGroup(false);
      await dispatch(listRoomsByGroupId(Number(groupId)));
    }
  };

  const handleListAvailableMoodleRooms = async (id: number) => {
    try {
      const res = await dispatch(listAvailableMoodleRooms(id));
      if (res.payload.length > 0) {
        const resSerialized = serializePayload(res.payload);
        setAvailableMoodleRooms(resSerialized);
      } else {
        setAvailableMoodleRooms([]);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleConfirmChangeRoom = async (id: any) => {
    try {
      await patchTransferStudentToRoom(id, idCurrentRoom, idStudentToMove);

      closeModalChangeRoom();

      setIsSuccessAlert(true);

      setTimeout(() => {
        setIsSuccessAlert(false);
      }, 3000);

      setActiveTabLcl(1);

      setRefetchListRoomsByGroupId((prev: boolean) => !prev);
    } catch (error) {
      closeModalChangeRoom();

      setIsErrorAlert(true);

      setTimeout(() => {
        setIsErrorAlert(false);
      }, 3000);
    }
  };

  const customCellDefault = ({ cell }: any) => <>{cell.value || '-'}</>;

  const customCellEmailTutor = (props: any) => {
    return (
      <>
        {props?.row?.original?.guardian?.id ? (
          <ButtonLineal
            hasPadding={false}
            disabled={props?.row?.original?.guardian?.id ? false : true}
            onClick={() => {
              setIdTutor(Number(props?.row?.original?.guardian?.id));
              openModalDetailTutor();
            }}
          >
            <Typography type="paragraph2" variation="mobile">
              {props?.row?.original?.guardian?.email}
            </Typography>
          </ButtonLineal>
        ) : (
          <>{'-'}</>
        )}
      </>
    );
  };

  const customCellProjectId = (props: any) => {
    return (
      <div style={{ width: props.column.width, ...cellProjectId }}>
        <span>{props.row.original.project_id || '-'}</span>
        <ModalEditProjectId
          setRefetchListRoomsByGroupId={setRefetchListRoomsByGroupId}
          groupId={groupId}
          student={props.row.original}
          projectId={props.row.original.project_id}
        />
      </div>
    );
  };

  const customCellRoom = (props: any) => (
    <>
      {rooms.length > 0 ? (
        <UpdateStudentRoom
          studentId={props.row.original.id}
          rooms={rooms}
          room={props.row.original.room}
          groupId={groupId}
        />
      ) : (
        <Tooltip left="3rem" bg="#595C5C" title="El grupo aún no tiene salones">
          <UpdateStudentRoom
            studentId={props.row.original.id}
            rooms={rooms}
            room={props.row.original.room}
            groupId={groupId}
          />
        </Tooltip>
      )}
    </>
  );

  const customCellGoTo = (props: any) => {
    const style: any = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'flex-start',
      width: '195px',
      height: '95px',
      paddingLeft: '10px'
    };
    const isDevEnvironment =
      process.env.REACT_APP_CTC_BACKEND_URL ===
      'https://backend.devcrackthecode.net/api';

    const showTransferRoomOption = isDevEnvironment
      ? true
      : getUserToShowOption();

    return (
      <FloatingMenu
        openDirection="right"
        items={[
          <div
            key="item-floating-menu-0"
            style={
              props.row.original.is_template_mirror && showTransferRoomOption
                ? style
                : {}
            }
          >
            {props.row.original.is_template_mirror && showTransferRoomOption ? (
              <ItemFloatingMenu
                key="item-floating-menu-1"
                color={'#313131'}
                onClick={() => {
                  setNameStudentToMove(props.row.original.full_name);
                  setIdStudentToMove(props.row.original.id);
                  setIdCurrentRoom(props.row.original.room.id);
                  handleListAvailableMoodleRooms(props.row.original.room.id);
                  openModalChangeRoom();
                }}
              >
                Transferir a otro salón
              </ItemFloatingMenu>
            ) : null}
            <ItemFloatingMenu
              key="item-floating-menu-2"
              color={colors.colorAlertRed}
              onClick={() => {
                setIdStudent(props.row.original.id);
                openModalDeleteStudent();
              }}
            >
              Eliminar estudiante
            </ItemFloatingMenu>
          </div>
        ]}
      />
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Acciones',
        accessor: 'action',
        actionWidth: 160,
        hasTooltip: true,
        tooltipValue: 'Ir a Zoom',
        tooltipValue1: 'Ir a Wiki',
        Cell: customCellGoTo,
        align: 'center',
        headerPagination: true,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Nombre del estudiante',
        accessor: 'full_name',
        classColumn: 'name-group',
        Cell: customCellDefault,
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Project ID',
        accessor: 'project_id',
        classColumn: 'name-teacher',
        widthColumn: 120,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellProjectId
      },
      {
        Header: 'Edad',
        accessor: 'age',
        classColumn: 'name-teacher',
        widthColumn: 142,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellDefault
      },
      {
        Header: 'Correo tutor/Madre/Padre',
        accessor: 'guardian.email',
        classColumn: 'status-evaluation',
        widthColumn: 280,
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellEmailTutor
      },
      {
        Header: 'Salón',
        accessor: 'room',
        classColumn: 'end-date-evaluation',
        widthColumn: 300,
        disableSortBy: true,
        disableFilters: true,
        columnType: 'component',
        Cell: customCellRoom
      }
    ],
    []
  );

  return (
    <div>
      {/* Button Añadir estudiante al grupo */}
      <div
        style={{
          color: '#9F760B',
          background: '#FFF1CC',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 12,
          borderRadius: 8,
          marginBottom: 24
        }}
      >
        <Icon iconName="info" />
        <p style={{ margin: 0, marginLeft: 16, fontSize: 14, fontWeight: 500 }}>
          Al cambiar el salón, si las clases ya comenzaron, los datos del
          estudiante no se transferirán. Para hacerlo, deberás realizar la
          transferencia desde la sección “Acciones”.
        </p>
      </div>
      <ButtonFilled fullWidth size="small" onClick={openModalAddStudentToGroup}>
        Agregar estudiante al grupo
      </ButtonFilled>
      <br />
      {/* Table Students */}
      <Table data={students} columns={columns} />
      {/* Modal Añadir estudiante al grupo */}
      <Modal
        dividers={false}
        title="Añadir estudiante al grupo"
        fullWidth
        maxWidth="sm"
        openModal={isOpenModalAddStudentToGroup}
        handleCloseModal={closeModalAddStudentToGroup}
      >
        {complementStudents && (
          <AddStudentToGroup
            complementStudents={complementStudents}
            groupId={groupId}
            onClose={closeModalAddStudentToGroup}
          />
        )}
      </Modal>
      {idTutor ? (
        <Modal
          id={idTutor}
          title="Tutor / Madre / Padre"
          copyUrl
          fullWidth
          maxWidth="xl"
          openModal={isOpenModalDetailTutor}
          handleCloseModal={closeModalDetailTutor}
        >
          <GuardianDetails guardianId={Number(idTutor)} />
        </Modal>
      ) : null}
      {idStudent ? (
        <ModalMessageAlert
          className="teacher-profile__modal-alert"
          width="sm"
          align="center"
          title={
            <Typography bold type="h1" variation="mobile" align="center">
              ¿Quieres eliminar el estudiante {idStudent}?
            </Typography>
          }
          isOpenModal={isOpenModalDeleteStudent}
          openModal={openModalDeleteStudent}
          closeModal={closeModalDeleteStudent}
          body={
            <Typography
              type="paragraph2"
              variation="mobile"
              color={colors.gris05}
              align="center"
            >
              Al eliminarlo no se podrá volver a recuperar la información del
              estudiante {idStudent}
            </Typography>
          }
          button1={
            <ButtonFilled
              fullWidth
              disabled={isLoadingDeleteGroup}
              onClick={() =>
                handleDeleteGroupStudent(Number(groupId), Number(idStudent))
              }
            >
              Eliminar
            </ButtonFilled>
          }
          button2={
            <ButtonLineal
              disabled={isLoadingDeleteGroup}
              onClick={closeModalDeleteStudent}
            >
              Cancelar
            </ButtonLineal>
          }
        />
      ) : null}
      <ModalCTC
        key={idStudentToMove}
        type="big"
        // title='Cambiar estudiante de salón'
        isModalOpen={isOpenModalChangeRoom}
        // closeIcon={false}
        bigTypeContent={
          <ChangeRoomModal
            isOpen={isOpenModalChangeRoom}
            onClose={closeModalChangeRoom}
            onConfirm={handleConfirmChangeRoom}
            studentName={nameStudentToMove}
            availableRooms={availableMoodleRooms}
            /* availableRooms={[
              { id: '1', label: 'room 1', value: 'room 1' },
              { id: '2', label: 'room 1', value: 'room 2' }
            ]} */
          />
        }
        handleClose={closeModalChangeRoom}
      />
      {isSuccessAlert ? (
        <AlertSnackbar
          message={`Estudiante cambiado de salón correctamente`}
          severity="success"
        />
      ) : null}
      {isErrorAlert ? (
        <AlertSnackbar
          message={`Ocurrió un error al cambiar al estudiante de salón`}
          severity="error"
        />
      ) : null}
    </div>
  );
};

export default Students;
