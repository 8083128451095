/* eslint-disable complexity */
/* eslint-disable max-lines */
import moment from 'moment';
import 'moment/locale/es';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { Tooltip as TooltipMUI } from '@mui/material';
import { FormControl, SelectChangeEvent } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Table,
  Icon,
  Toggle,
  Tooltip,
  Typography,
  Dropdown,
  DropdownFilter,
  Button,
  TooltipWrapper,
  Modal,
  Badge,
  Popover
} from 'ctc-component-library';

import './attendanceStudent.scss';
import {
  AttendanceType,
  getListAbsenceReasons,
  patchAttendanceMutation,
  patchBulkAttendanceToday,
  postCreateUniqueAttendance
} from '../../../services/attendance';
import './tableAttendanceStudent.scss';
import colors from '../../atoms/Colors';
import { LazySelect } from './LazySelect';
import ModalLocal from '../../atoms/Modal';
import {
  IStudent,
  IDateSession,
  IStudentRecord,
  IRoomDetailSimplified,
  ISessionSelect,
  IScheduleGroup,
  ICancellationReason,
  ICancellationReasonFilter
} from '../../../interfaces/room.interface';
import {
  ADMIN,
  TEACHER_AUXILIARY,
  TEACHER_CTC
} from '../../../constants/usersType.constants';
import { RootState } from '../../../store/store';
import IconInfo from '../../atoms/icons/IconInfo';
import { useModal } from '../../../hooks/useModal';
import IconLoader from '../../atoms/icons/IconLoader';
import Paragraph from '../../common/Paragraph/Paragraph';
import NewTypography from '../../atoms/Typography/Typography';
import ModalJustification from '../../molecules/ModalJustification';
import { addSesion, disableSesion } from '../../../services/rooms';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import ButtonLineal from '../../molecules/ButtonLineal/ButtonLineal';
import { patchChangeEnrollmentState } from '../../../services/students';
import { AlertSnackbar } from '../../common/AlertSnackbar/AlertSnackbar';
import { CancellationReasons } from '../../../interfaces/cancellationSession';
import {
  getCancellationReasons,
  patchRiskCancellation,
  patchModality
} from '../../../services/cancellationSession';
import IconOutlinedInformation from '../../atoms/icons/IconOutlinedInformation';
import useInactiveStudentReasons from '../../../hooks/useInactiveStudentReasons';
import { ContentCancellationRisk } from './ContentCancellationRisk/ContentCancellationRisk';
import ModalInactiveStudent from '../../molecules/ModalInactiveStudent/ModalInactiveStudent';
import ModalInactiveStudentsReasons from '../ModalInactiveStudentsReasons/ModalInactiveStudentsReasons';
import { useToast } from '../../../hooks/useToast';

moment.locale('es');

interface Props {
  roomInfo: IRoomDetailSimplified;
  studentRecords: IStudentRecord[] | [];
  refetch: () => void;
  refetchAttendanceDetails: (allSession?: boolean) => void;
  setOpenModal: any;
  setValueDateId: any;
  setValueDate: any;
  refetchDetailHeader: () => void;
  fetchStatusStudentsByRoom: (roomId: number) => void;
  qtySessions: any;
  scheduleByRoom: IScheduleGroup;
}

const TableAttendanceStudentV3: React.FC<Props> = ({
  roomInfo,
  setOpenModal,
  setValueDate,
  studentRecords,
  setValueDateId,
  refetch,
  refetchDetailHeader,
  fetchStatusStudentsByRoom,
  refetchAttendanceDetails,
  qtySessions,
  scheduleByRoom
}) => {
  const currentTime = moment();
  const { showToast } = useToast();
  const [copied, setCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState('');
  const user = useSelector((state: RootState) => state.auth.user);
  const isAdmin = user?.permission.name === ADMIN;
  const isB2B = roomInfo?.business_type === 'B2B';
  const mainTeacher = roomInfo?.main_teacher;
  const {
    openModal: openModalBulkAttendanceToday,
    closeModal: closeModalBulkAttendanceToday,
    isOpenModal: isOpenModalBulkAttendanceToday
  } = useModal();
  const {
    openModal: openModalUpdateCancellationReason,
    closeModal: closeModalUpdateCancellationReason,
    isOpenModal: isOpenModalUpdateCancellationReason
  } = useModal();
  const {
    openModal: openModalDeleteSesion,
    closeModal: closeModalDeleteSesion,
    isOpenModal: isOpenModalDeleteSesion
  } = useModal();
  const {
    openModal: openModalAddSesion,
    closeModal: closeModalAddSesion,
    isOpenModal: isOpenModalAddSesion
  } = useModal();
  const {
    openModal: openModalAddJustification,
    closeModal: closeModalAddJustification,
    isOpenModal: isOpenModalAddJustification
  } = useModal();
  const {
    openModal: openModalDetailJustification,
    closeModal: closeModalDetailJustification,
    isOpenModal: isOpenModalDetailJustification
  } = useModal();
  const {
    openModal: openModalCancellationRisk,
    closeModal: closeModalCancellationRisk,
    isOpenModal: isOpenModalCancellationRisk
  } = useModal();

  const [selectSesion, setSelectSesion] = useState<any>();
  const [selectIdxColumn, setSelectIdxColumn] = useState<number>();
  const [selectSesionNumber, setSelectSesionNumber] = useState<any>();
  const [openPopover, setOpenPopover] = useState({
    session: 0,
    open: false,
    cancellationReason: {
      id: 0,
      label: '',
      value: '',
      component: <></>
    },
    isPaidSession: false,
    idxColumn: 0
  });
  const [cancellationRisk, setCancellationRisk] = useState({
    session: 0,
    hasRisk: false
  });
  const [reasonOptions, setReasonOptions] = useState<any>();
  const [dataCancellationReasons, setDataCancellationReasons] = useState([]);
  const [cancellationReasonSelected, setCancellationReasonSelected] =
    useState<ICancellationReasonFilter | null>();
  const [
    hasChangeValueCancellationReason,
    setHasChangeValueCancellationReason
  ] = useState(false);
  const [studentName, setStudentName] = useState<any>();
  const [studentState, setStudentState] = useState<any>();
  const [studentId, setStudentId] = useState<any>();
  const [currentSessionId, setCurrentSessionId] = useState<any>();
  const [currentStudent, setCurrentStudent] = useState<any>();
  const [addSessionInProgress, setAddSessionInProgress] = useState<any>(false);
  const [editCancellationReason, setEditCancellationReason] =
    useState<any>(false);
  const [payloadInactiveStudent, setPayloadInactiveStudent] = useState<any>();
  const [inactiveStudentReason, setInactiveStudentReason] = useState<any>();
  const [listReasonAbsence, setListReasonAbsence] = useState<any>();
  const [reasonAbsence, setReasonAbsence] = useState({ id: 0, name: '' });
  const [typeModalJustification, setTypeModalJustification] = useState<
    'add' | 'edit'
  >('add');
  const [tableDataSource, setTableDataSource] = useState<any>();
  const [columnsTable, setColumnsTable] = useState();
  const [isLoadingBulkAttendanceToday, setIsLoadingBulkAttendanceToday] =
    useState(false);
  const [isTakedBulkAttendanceToday, setIsTakedBulkAttendanceToday] =
    useState(false);

  const statusClasses: { [key: string]: string } = {
    0: 'table-attendance-student__null',
    1: 'table-attendance-student__attended',
    2: 'table-attendance-student__delay',
    3: 'table-attendance-student__absence',
    4: 'table-attendance-student__justified',
    5: 'table-attendance-student__recovered',
    6: 'table-attendance-student__material',
    7: 'table-attendance-teacher__justified',
    default: 'table-attendance-student__disable'
  };

  const statusColors: { [key: string]: string } = {
    0: colors.gris05,
    1: colors.statusAttendedText,
    2: colors.statusDelayText,
    3: colors.statusAbsenceText,
    4: colors.statusJustifiedText,
    5: colors.statusRecoveredText,
    6: colors.statusMaterialText,
    7: colors.statusJustifiedText,
    default: colors.gris03
  };

  const canAddJustificationReason = (status: any) => {
    const possibleValues = [3, 4];

    if (possibleValues.includes(status)) {
      return true;
    }
    return false;
  };

  const getStatusColor = (status: any) => {
    const colorMap: any = {
      done: colors.statusAttendedText,
      risk: colors.statusDelayText,
      default: colors.statusAbsenceText
    };
    return colorMap[status] || colorMap.default;
  };

  const getStatusLabel = (status: any) => {
    const labelMap: any = {
      done: 'Activo',
      risk: 'Riesgo',
      inactive: 'Inactivo',
      default: 'Abandono'
    };
    return labelMap[status] || labelMap.default;
  };

  const listReasonsAbsence = async () => {
    try {
      const response = await getListAbsenceReasons();
      setListReasonAbsence(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewSesion = async (roomId: number) => {
    try {
      setAddSessionInProgress(true);
      const response = await addSesion(roomId);
      if (response?.status === 201) {
        setAddSessionInProgress(false);
        toast.success('¡Se agregó la sesión correctamente!');
        await refetchAttendanceDetails(true);
        await refetch();
        qtySessions.current = { value: 'all', label: 'Todas las sesiones' };
      } else {
        setAddSessionInProgress(false);
        toast.error('No se pudo agregar la sesión');
      }
    } catch (error) {
      setAddSessionInProgress(false);
      toast.error('Ha ocurrido un error al crear la sesión');
    }
  };

  const handleDeleteSesion = async (
    isCancel: boolean,
    room_id: number,
    session_id: number,
    state_session: boolean,
    reason_id?: number,
    is_paid?: boolean
  ) => {
    try {
      const response = await disableSesion({
        room_id,
        session_id,
        state_session,
        reason_id,
        is_paid
      });

      const okResponse = editCancellationReason
        ? `Cambios en la sesión ${openPopover?.session} realizados con éxito`
        : `La sesión ${openPopover?.session} fue cancelada.`;

      const finalOkResponse = isCancel
        ? okResponse
        : 'Sesión habilitada con éxito';

      const failResponse = editCancellationReason
        ? 'Ocurrió un error al aplicar los cambios. Vuelve a intentarlo.'
        : 'Ocurrió un error al cancelar la sesión. Vuelve a intentarlo.';

      const finalFailResponse = !isCancel
        ? failResponse
        : 'Ocurrió un error al activar la sesión. Vuelve a intentarlo';

      if (response?.status === 200) {
        showToast(finalOkResponse, 'success', 4000);
        refetch();
      } else {
        showToast(finalFailResponse, 'error', 3000);
      }
    } catch (error) {
      showToast(
        editCancellationReason
          ? 'Ocurrió un error al aplicar los cambios. Vuelve a intentarlo.'
          : 'Ocurrió un error al cancelar la sesión. Vuelve a intentarlo.',
        'error',
        3000
      );
    }
  };

  const createUniqueAttendance = async (attendance: any) => {
    try {
      const data = await postCreateUniqueAttendance(attendance);
      if (data) {
        toast.success('Asistencia tomada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al tomar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al tomar la asistencia.');
    }
  };

  const updateAttendanceMutation = async (attendance: any) => {
    try {
      const data = await patchAttendanceMutation(attendance);
      if (data) {
        toast.success('Asistencia actualizada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al actualizar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al actualizar la asistencia.');
    }
  };

  const handleSubmitUniqueAttendance = async (
    event: any,
    numberOfSession: any,
    roomId: any,
    student: any
  ) => {
    const newAttendance = {
      number_of_session: numberOfSession,
      status: event.target.value,
      object_id: student,
      object_type: AttendanceType.student,
      room: roomId
    };

    await createUniqueAttendance({ ...newAttendance });
    refetchAttendanceDetails(
      qtySessions.current.value === 'all' ? true : false
    );
  };

  const handleUpdateStateAttendance = async (event: any, attendance: any) => {
    await updateAttendanceMutation({
      id: attendance.id,
      status: event.target.value
    });
    refetchAttendanceDetails(
      qtySessions.current.value === 'all' ? true : false
    );
  };

  const closeCancelModalBulkAttendanceStudent = async () => {
    closeModalBulkAttendanceToday();
    setIsLoadingBulkAttendanceToday(false);
  };

  const takeTodaysBulkAttendance = async (session: any) => {
    await handleStudentBulkAttendanceToday('student', session);
    closeModalBulkAttendanceToday();
    refetchAttendanceDetails(
      qtySessions.current.value === 'all' ? true : false
    );
  };

  const deleteSesion = async (sesion: any) => {
    handleDeleteSesion(
      true,
      roomInfo?.id,
      sesion,
      false,
      openPopover.cancellationReason?.id,
      openPopover.isPaidSession
    );
    handleCloseModalDeleteSession();
  };

  const handleCloseModalDeleteSession = async () => {
    closeModalDeleteSesion();
    setCancellationReasonSelected(null);
    setHasChangeValueCancellationReason(false);
    setEditCancellationReason(false);
  };

  const habilitarSesion = async (sesion: any) => {
    handleDeleteSesion(false, roomInfo?.id, sesion, true);
    closeModalAddSesion();
  };

  const getActiveSessions = (date_sessions: IDateSession[]) => {
    let numberOfSessions = 0;
    date_sessions.forEach(session => {
      if (session.state) {
        numberOfSessions += 1;
      }
    });
    return numberOfSessions;
  };

  const {
    openModal: openModalAddTeacherEtario,
    closeModal: closeModalAddTeacherEtario,
    isOpenModal: isOpenModalAddTeacherEtario
  } = useModal();

  const {
    openModal: openModalActiveStudent,
    closeModal: closeModalActiveStudent,
    isOpenModal: isOpenModalActiveStudent
  } = useModal();

  const {
    openModal: openModalConfirmActive,
    closeModal: closeModalConfirmActive,
    isOpenModal: isOpenModalConfirmActive
  } = useModal();

  const handleConfirm = () => {
    closeModalActiveStudent();
    openModalConfirmActive();
  };

  const { data } = useInactiveStudentReasons({ shouldRefetch: false });

  const memorizedData = useMemo(() => data, [data]);

  useEffect(() => {
    setReasonOptions(memorizedData);
  }, [memorizedData]);

  const handleOpenModalInactiveStudent = ({
    studentName,
    studentId
  }: {
    studentName: any;
    studentId: any;
  }) => {
    setStudentName(studentName);
    setStudentId(studentId);
    openModalAddTeacherEtario();
  };

  const handleActiveStudent = async () => {
    closeModalConfirmActive();

    const data = await patchChangeEnrollmentState(payloadInactiveStudent);
    if (data && data?.status === 200) {
      toast.success('Se activó el estudiante');
      refetchAttendanceDetails(
        qtySessions.current.value === 'all' ? true : false
      );
      refetch();
      return data;
    } else {
      toast.error('No se activó el estudiante');
    }
  };

  const baseMenuItems = [
    {
      value: 1,
      message: 'A',
      status: 'attended',
      minWidth: 105,
      addHR: true
    },
    {
      value: 2,
      message: 'T',
      status: 'delay',
      addHR: true
    },
    {
      value: 3,
      message: 'F',
      status: 'absence',
      addHR: true
    },
    {
      value: 4,
      message: 'J',
      status: 'justified',
      addHR: true
    }
  ];

  const adminExtraItems = [
    {
      value: 5,
      message: 'R',
      status: 'recovered',
      addHR: true
    },
    {
      value: 6,
      message: 'MR',
      status: 'material',
      addHR: true
    },
    {
      value: 7,
      message: 'N/A',
      status: 'N/A'
    }
  ];

  const RenderInactiveActions = ({ row }: { row: any }) => {
    return row.enrolment_status === 'inactive' ? (
      <button
        style={{
          border: '0px',
          backgroundColor: 'inherit',
          cursor: 'pointer'
        }}
        onClick={() => {
          const payload = {
            student_id: row.id,
            room_id: roomInfo?.id,
            state: 'done'
          };
          setStudentState(row.enrolment_status);
          setPayloadInactiveStudent(payload);
          setStudentName(row.full_name);
          setStudentId(row.id);
          setInactiveStudentReason(row.reason);
          openModalActiveStudent();
        }}
      >
        <IconInfo width="14" height="16" fill={'#D84949'} />
      </button>
    ) : user?.permission.name === ADMIN ? (
      <button
        style={{
          border: '0px',
          backgroundColor: 'inherit',
          cursor: 'pointer'
        }}
        onClick={() => {
          setInactiveStudentReason(null);
          setStudentId(row.id);
          setStudentState(row.enrolment_status);
          handleOpenModalInactiveStudent({
            studentName: row.full_name,
            studentId: row.id
          });
        }}
      >
        <Icon iconName="edit" customClassName="icon-edit_pencil" />
      </button>
    ) : null;
  };

  const disabledSessionBox = ({
    session,
    attendanceForCurrentSession,
    row
  }: {
    session: any;
    attendanceForCurrentSession: any;
    row: any;
  }) => {
    const isMainTacherCTC =
      user?.permission.name === TEACHER_CTC && mainTeacher.id === user?.id;
    const isAdmin = user?.permission.name === ADMIN;
    const isAuxTeacher = user?.permission.name === TEACHER_AUXILIARY;
    const isReplacementTeacherCTC =
      user?.permission.name === TEACHER_CTC && mainTeacher.id !== user?.id;
    const possibleAttendanceValue = [5, 6, 7];

    if (
      possibleAttendanceValue.includes(attendanceForCurrentSession?.status) &&
      !isAdmin
    ) {
      return false;
    }

    if ((isMainTacherCTC || isAdmin || isAuxTeacher) && session.state) {
      return false;
    }

    if ((isMainTacherCTC || isAdmin || isAuxTeacher) && !session.state) {
      return true;
    }

    if (isReplacementTeacherCTC && session.teacher_id !== user?.id) {
      return true;
    }

    if (isReplacementTeacherCTC && session.teacher_id === user?.id) {
      return true;
    }

    return false;
  };

  const RenderButtonAddSession = ({roomInfo}: {
    roomInfo: IRoomDetailSimplified;
  }) => {
    const isButtonDisabled = roomInfo?.extra_session_number >= 5;

    const sessionNumber = isButtonDisabled
      ? '(0)'
      : `(${5 - Number(roomInfo?.extra_session_number)})`;

    const button = (
      <Button
        disabled={isButtonDisabled || addSessionInProgress}
        type="tertiary"
        text={`Añadir sesión ${sessionNumber}`}
        customBtnClassName={
          isButtonDisabled || addSessionInProgress
            ? 'table-attendance-student__button-add-session-disabled'
            : undefined
        }
        onClick={() => handleAddNewSesion(roomInfo?.id)}
      />
    );

    return isButtonDisabled ? (
      <TooltipMUI
        classes={{ tooltip: 'custom-tooltip' }}
        PopperProps={{ style: { maxWidth: 150 } }}
        title="Ya se agregaron las sesiones máximas permitidas."
        arrow
        placement="top"
      >
        {button}
      </TooltipMUI>
    ) : (
      button
    );
  };

  const showModalDetailJustification = (
    attendanceForCurrentSession: any
  ): void => {
    openModalDetailJustification();
    setReasonAbsence(
      attendanceForCurrentSession?.absence_reason || { id: 0, name: '' }
    );
  };

  const showModalAddJustification = (
    typeModalAdmin: 'add' | 'edit',
    row: any,
    attendanceForCurrentSession: any
  ): void => {
    setTypeModalJustification(typeModalAdmin);
    setReasonAbsence(
      attendanceForCurrentSession?.absence_reason || { id: 0, name: '' }
    );
    openModalAddJustification();
    listReasonsAbsence();
    setCurrentStudent(row.full_name);
    setCurrentSessionId(attendanceForCurrentSession?.id);
  };

  const handleStudentBulkAttendanceToday = async (
    entityType: 'student' | 'teacher' | 'auxiliar_teacher',
    sessionId: number
  ) => {
    setIsLoadingBulkAttendanceToday(true);
    try {
      const response = await patchBulkAttendanceToday({
        entityType,
        sessionId
      });
      if (response?.status === 200) {
        toast.success('Asistencia actualizada correctamente!');
        setIsTakedBulkAttendanceToday(true);
        setTimeout(() => {
          setIsLoadingBulkAttendanceToday(false);
        }, 1000);
      } else {
        toast.error('Ha ocurrido un error al actualizar la asistencia.');
        setIsLoadingBulkAttendanceToday(false);
      }
    } catch (err) {
      toast.error('Ha ocurrido un error al actualizar la asistencia.');
      setIsLoadingBulkAttendanceToday(false);
    }
  };

  const columnsTableMap: any = [
    {
      title: () => (
        <>
          <Typography variant="body5">
            <strong>Id</strong>
          </Typography>
        </>
      ),
      key: 'id',
      fixed: 'left',
      width: 100,
      render: (_: any, { id }: any) => (
        <Typography variant="body5">
          <strong>{id}</strong>
        </Typography>
      )
    },
    {
      title: () => (
        <>
          <Typography variant="body5">
            <strong>Nombre y apellido</strong>
          </Typography>
        </>
      ),
      dataIndex: 'full_name',
      key: 'full_name',
      width: 300,
      fixed: 'left',
      align: 'left',
      render: (text: string, record: any) => {
        return (
          <>
            <Tooltip
              prompText={`${record.last_name}, ${record.first_name}`}
              customClassName="table-attendance-student__justify-content-left"
            >
              <Typography variant="body5">
                {record.last_name}, {record.first_name}
              </Typography>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: () => (
        <>
          <Typography variant="body5">
            <strong>Estado</strong>
          </Typography>
        </>
      ),
      key: 'state',
      width: 160,
      fixed: 'left',
      align: 'left',
      render: (row: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left'
          }}
        >
          <span
            style={{
              background: colors.gris01,
              borderRadius: '8px',
              gap: 4,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '100px',
              padding: '8px 4px'
            }}
          >
            <Typography
              variant="body5"
              customStyles={{ color: getStatusColor(row.enrolment_status) }}
            >
              {getStatusLabel(row.enrolment_status)}
            </Typography>
          </span>
          {isB2B && <RenderInactiveActions row={row} />}
        </div>
      )
    },
    {
      title: () => (
        <>
          <Typography variant="body5">
            <strong>Datos</strong>
          </Typography>
        </>
      ),
      key: 'data',
      width: 100,
      fixed: 'left',
      render: (row: any) => (
        <>
          <TooltipWrapper
            customStyles={{ width: '397px' }}
            customClassName="table-attendance-student__justify-content-left"
            content={
              <div>
                <div style={{ borderBottom: '1px solid #EDF2F0' }}>
                  <Typography
                    variant="body5"
                    className="room-details-credentials"
                  >
                    <strong>Edad</strong>
                    <br />
                    <Paragraph text={`${row.age} años`} direction="right" />
                  </Typography>
                </div>
                <div style={{ borderBottom: '1px solid #EDF2F0' }}>
                  <Typography
                    variant="body5"
                    className="room-details-credentials"
                  >
                    <strong>Country</strong>
                    <br />
                    <Paragraph text={row.country} direction="right" />
                  </Typography>
                </div>
                <div style={{ borderBottom: '1px solid #EDF2F0' }}>
                  <Typography
                    variant="body5"
                    className="room-details-credentials"
                  >
                    <strong>Email</strong>
                    <br />
                    <Paragraph text={row.email} copyable direction="right" />
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body5"
                    className="room-details-credentials"
                  >
                    <strong>Contraseña Moodle y Gmail</strong>
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body5"
                      className="room-details-credentials"
                    >
                      <Paragraph
                        copyableText={'item'}
                        text={`Moodle: ${row.password} Gmail: ${row.password_google}`}
                        hideable
                        direction="right"
                      />
                    </Typography>
                    <TooltipMUI
                      title={`${copied ? 'Copiado' : 'Copiar'}`}
                      arrow
                      placement="left"
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (row.password) {
                            setCopied(true);
                            setCopiedValue(row.password);
                            setTimeout(() => setCopied(false), 1200);
                            navigator.clipboard.writeText(`${row.password}`);
                          }
                        }}
                      >
                        <Icon iconName="content_copy" />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
              </div>
            }
          >
            <div
              style={{
                border: '0px',
                backgroundColor: 'inherit',
                cursor: 'pointer'
              }}
            >
              <span style={{ color: '#171A1A' }}>
                <Icon iconName="contact_page" />
              </span>
            </div>
          </TooltipWrapper>
        </>
      )
    }
  ];

  const ctaBulkAttendance = (
    isLoadingBulkAttendanceToday: boolean,
    isGoingToStartTodaySession: boolean,
    localSessionId: number,
    columnIdx: number,
    takedBulkAttendanceStudent: boolean
  ) => {
    if (isLoadingBulkAttendanceToday) {
      return (
        <div style={{ marginTop: 4 }}>
          <IconLoader className="table-attendance-student__icon-loader" />
        </div>
      );
    } else {
      return (
        <Button
          disabled={
            !isGoingToStartTodaySession ||
            isLoadingBulkAttendanceToday ||
            takedBulkAttendanceStudent
          }
          type="tertiary"
          size="small"
          text="Asistencia a todos"
          customBtnClassName="table-attendance-student__bulk-attendance-today"
          onClick={() => {
            setSelectIdxColumn(columnIdx);
            setSelectSesion(localSessionId);
            openModalBulkAttendanceToday();
          }}
        />
      );
    }
  };

  const openPopoverSession = (localSession: any, idxColumn: number) => {
    setSelectIdxColumn(idxColumn);
    setOpenPopover({
      session: localSession?.session_number,
      open: false,
      cancellationReason: localSession?.cancellation_reason,
      isPaidSession: localSession?.is_paid_session || false,
      idxColumn: idxColumn
    });
    setSelectSesionNumber(localSession?.session_number);
    openModalDeleteSesion();
    listCancellationReasons();
  };

  const handleOpenPopoverChange = (
    newOpen: boolean,
    localSession: any,
    idxColumn: number
  ) => {
    setOpenPopover({
      session: localSession?.session_number,
      open: newOpen,
      cancellationReason: convertListCancellationReasons(
        localSession?.cancellation_reason
      ),
      isPaidSession: localSession?.is_paid_session || false,
      idxColumn: idxColumn
    });
  };

  const handleChangeToggle = async (value: boolean, sessionId: number) => {
    try {
      const { status } = await patchRiskCancellation(sessionId, value);
      if (status === 200) {
        setCancellationRisk({
          session: sessionId,
          hasRisk: value
        });
        refetchAttendanceDetails(
          qtySessions.current.value === 'all' ? true : false
        );
      }
      setOpenPopover({ ...openPopover, open: false });
    } catch (error) {
      console.log('error risk cancellation', error);
    }
  };

  const handleChangeModality = async (value: boolean, sessionId: number) => {
    try {
      const res = await patchModality(sessionId, value);
      if (res.status === 200) {
        /* setCancellationRisk({
          session: sessionId,
          modality: value
        }); */
        refetchAttendanceDetails(
          qtySessions.current.value === 'all' ? true : false
        );
      }
      setOpenPopover({ ...openPopover, open: false });
    } catch (error) {
      console.log('error change modality', error);
    }
  };

  const handleChangeTogglePaidTeacher = async (value: boolean) => {
    const localSession = studentRecords[0]?.sessions[openPopover.idxColumn];

    if (localSession.is_paid_session !== value) {
      setHasChangeValueCancellationReason(true);
    }
    setOpenPopover({ ...openPopover, isPaidSession: value });
  };

  const pushSessionColumns = (studentRecords: any, columnsTableMap: any) => {
    studentRecords &&
      studentRecords[0]?.sessions &&
      Object.keys(studentRecords[0]?.sessions)?.map(
        (session: any, idx: number) => {
          const todayFormat = moment().format('YYYY-MM-DD');
          const localSession = studentRecords[0]?.sessions[session];
          const localSessionId = Number(localSession?.id);
          const isActiveSession = localSession.state;
          const currentSessionDate = moment(localSession.start_date).format(
            'dd. DD/MM/YY'
          );
          const isExpiredSession = moment(localSession?.start_date).isBefore(
            todayFormat
          );
          const isFutureSession = moment(localSession?.start_date).isAfter(
            todayFormat
          );
          const scheduleTimeByCourse = moment(
            studentRecords[0]?.sessions[session]?.start_time,
            'HH:mm:ss'
          );

          const differenceInMinutes = scheduleTimeByCourse.diff(
            currentTime,
            'minutes'
          );
          const todaySession = moment(
            localSession.start_date,
            'YYYY-MM-DD'
          ).isSame(todayFormat, 'day');
          const isGoingToStartTodaySession =
            isActiveSession && differenceInMinutes <= 15 && todaySession;

          const takedBulkAttendanceStudent =
            isTakedBulkAttendanceToday || localSession.bool_attendance;

          const onSite = localSession?.session_modality === 'on_site';

          columnsTableMap.push({
            align: 'center',
            className: 'table-attendance-student__table-th',
            title: () => (
              <div className="table-attendance-student__header-column-session">
                <div className="table-attendance-student__number-session">
                  <div
                    className={`table-attendance-student__number-session--type-container table-attendance-student__number-session--type-container-background-${onSite ? 'on-site' : 'virtual'} ${!isActiveSession ? 'table-attendance-student__number-session--type-container table-attendance-student__number-session--type-container-background-cancel' : ''}`}
                  >
                    <Icon
                      customClassName={`table-attendance-student__number-session--type-container-type-${onSite ? 'on-site' : 'virtual'} `}
                      iconName={`${onSite ? 'location_on' : 'laptop_windows'}`}
                    />
                  </div>
                  <Typography variant="body6">
                    <strong>{`Sesión ${localSession.session_number}`}</strong>
                  </Typography>
                  {isActiveSession ? (
                    <Popover
                      content={
                        <ContentCancellationRisk
                          showAttendanceToAll={!isFutureSession}
                          handleAttendanceToAll={() => {
                            setSelectSesion(localSession.id);
                            setOpenPopover({ ...openPopover, open: false });
                            openModalBulkAttendanceToday();
                          }}
                          onClick={() =>
                            openPopoverSession(localSession, session)
                          }
                          checkedToggle={
                            localSession?.risk_cancellation || false
                          }
                          onChangeToggle={(checked: boolean) =>
                            handleChangeToggle(checked, localSession.id)
                          }
                          onChangeModality={(val: boolean) => {
                            handleChangeModality(val, localSession.id);
                          }}
                          defaultCheckedItem={
                            localSession?.session_modality
                              ? localSession?.session_modality
                              : 'virtual'
                          }
                          isVisibleButton={isAdmin}
                        />
                      }
                      trigger="click"
                      arrow={false}
                      placement="bottom"
                      open={
                        openPopover.session === localSession.session_number
                          ? openPopover.open
                          : false
                      }
                      onOpenChange={newOpen => {
                        handleOpenPopoverChange(
                          newOpen,
                          localSession,
                          Number(session)
                        );
                        setSelectSesion(localSession.id);
                      }}
                    >
                      <div>
                        <Icon
                          customClassName={`table-attendance-student__${isExpiredSession ? 'icon-session-passed' : 'icon-next-session'} table-attendance-student__icon-more-vert-enabled`}
                          iconName="more_vert"
                        />
                      </div>
                    </Popover>
                  ) : null}
                  {localSession?.risk_cancellation && isActiveSession ? (
                    <Typography variant="body6">⚠️</Typography>
                  ) : null}
                  {!isActiveSession && isAdmin ? (
                    <button
                      className="table-attendance-student__number-session--active-session-button"
                      onClick={() => {
                        setSelectSesion(localSession.id);
                        openModalAddSesion();
                      }}
                    >
                      <Icon
                        customClassName={`table-attendance-student__number-session--active-session-icon`}
                        iconName={'add'}
                      />
                    </button>
                  ) : null}
                </div>
                <div className="table-attendance-student__date-session">
                  <Typography
                    variant="body6"
                    className={`table-attendance-student__${isExpiredSession ? 'session-passed' : 'next-session'} ${todaySession ? 'table-attendance-student__bold' : undefined}`}
                  >
                    {currentSessionDate}
                  </Typography>
                </div>
                <div className="table-attendance-student__state-session">
                  {isActiveSession ? (
                    <>
                      <Typography
                        variant="body6"
                        className={`table-attendance-student__${isExpiredSession ? 'session-passed' : 'next-session'} ${todaySession ? 'table-attendance-student__bold' : undefined}`}
                      >
                        {`${localSession.start_time.slice(0, -3)}hs | ${localSession.duration_time} min.`}
                      </Typography>
                    </>
                  ) : null}
                  {!isActiveSession ? (
                    <div className="table-attendance-student__container-badge-session-cancelled">
                      <div style={{ width: 16 }} />
                      <Badge
                        customClassName="table-attendance-student__badge-session-cancelled"
                        status="new"
                        variant="medium"
                        text="Cancelada"
                        type="quiet"
                      />
                      <Button
                        type="tertiary"
                        icon="info"
                        iconType="outlined"
                        iconPosition="right"
                        customBtnClassName="table-attendance-student__button-edit-cancellation-reason"
                        onClick={() => {
                          handleOpenPopoverChange(
                            false,
                            localSession,
                            Number(session)
                          );
                          setSelectSesion(localSession.id);
                          openModalUpdateCancellationReason();
                        }}
                      />
                    </div>
                  ) : isGoingToStartTodaySession ? (
                    ctaBulkAttendance(
                      isLoadingBulkAttendanceToday,
                      isGoingToStartTodaySession,
                      localSessionId,
                      idx,
                      takedBulkAttendanceStudent
                    )
                  ) : null}
                </div>
              </div>
            ),

            width: 200,
            render: (value: any) => {
              let studentAttendances = null;
              if (studentRecords && studentRecords.length > 0) {
                studentAttendances = studentRecords.find(
                  (studentRecord: any) => {
                    return studentRecord.student.id === value.id;
                  }
                );
              }

              const attendanceForCurrentSession = studentAttendances
                ? studentAttendances.attendances.find(
                    (attendance: any) =>
                      localSession.session_number ===
                      attendance.number_of_session
                  )
                : null;

              const attendanceStatus: number =
                attendanceForCurrentSession?.status || 0;

              const getAttendanceStatusClass = (): string => {
                if (!isActiveSession) {
                  return statusClasses.default;
                }

                return statusClasses[attendanceStatus] || statusClasses['0'];
              };

              const getColor = (): string => {
                if (!isActiveSession) {
                  return 'transparent';
                }

                return statusColors[attendanceStatus] || statusColors.default;
              };
              const cssClassAttendance = getAttendanceStatusClass();
              const isPossibleAddJustification =
                canAddJustificationReason(attendanceStatus);
              const colorAttendance = getColor();
              const isDisabledSessionBox = disabledSessionBox({
                session: localSession,
                attendanceForCurrentSession,
                row: value
              });

              const valNoAdminInfoJustification =
                (user?.permission.name !== ADMIN &&
                  attendanceForCurrentSession?.absence_reason?.id &&
                  attendanceStatus === 3) ||
                attendanceStatus === 4;

              const menuItemData: any =
                user?.permission.name === ADMIN
                  ? [...baseMenuItems, ...adminExtraItems]
                  : [
                      ...baseMenuItems,
                      ...adminExtraItems.filter(
                        item => item.value === attendanceStatus
                      )
                    ];

              const isTakingBulkAttendance =
                selectIdxColumn === idx && isLoadingBulkAttendanceToday;

              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%' //width: 170
                  }}
                >
                  {(isPossibleAddJustification ||
                    valNoAdminInfoJustification) &&
                  !isDisabledSessionBox ? (
                    <div style={{ width: 28 }} />
                  ) : null}
                  <FormControl
                    sx={{
                      '.Mui-disabled': {
                        WebkitTextFillColor:
                          attendanceForCurrentSession?.status == 5
                            ? `${colors.statusRecoveredText} !important`
                            : attendanceForCurrentSession?.status == 6
                              ? `${colors.statusMaterialText} !important`
                              : ''
                      },
                      m: 1
                    }}
                    variant="standard"
                    disabled={isTakingBulkAttendance || isDisabledSessionBox}
                  >
                    <LazySelect
                      key={`${value.id}-${localSession.session_number}`}
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={
                        attendanceForCurrentSession
                          ? attendanceForCurrentSession.status
                          : 0
                      }
                      defaultValue={0}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 50
                        }
                      }}
                      sx={{
                        '.MuiSelect-iconOpen': { paddingLeft: '16px' },
                        '.MuiMenuItem-root.Mui-selected': {backgroundColor: '#F6F6F6'},
                        '.MuiSelect-icon ': {
                          fill: colorAttendance,
                          paddingRight: '16px',
                          width: '40px'
                        },
                        color: isActiveSession ? colors.gris05 : colors.gris02
                      }}
                      IconComponent={KeyboardArrowDownIcon}
                      className={`table-attendance-student table-attendance-student__select 
                                ${cssClassAttendance} ${isActiveSession}-mico
                                ${
                                  isActiveSession
                                    ? ''
                                    : 'table-attendance-student__sesion-disable-attendance'
                                } 
                                ${isDisabledSessionBox ? 'disable-box' : ''}
                              `}
                      onChange={(event: SelectChangeEvent<number>) => {
                        if (attendanceForCurrentSession) {
                          handleUpdateStateAttendance(
                            event,
                            attendanceForCurrentSession
                          );
                        } else {
                          handleSubmitUniqueAttendance(
                            event,
                            localSession.session_number,
                            roomInfo.id,
                            value.id
                          );
                        }
                      }}
                      options={menuItemData}
                    />
                  </FormControl>
                  {isPossibleAddJustification && !isDisabledSessionBox ? (
                    <ButtonLineal
                      width={28}
                      hasPadding={false}
                      underline={false}
                      size="small"
                      onClick={() =>
                        showModalAddJustification(
                          attendanceForCurrentSession?.absence_reason?.id
                            ? 'edit'
                            : 'add',
                          value,
                          attendanceForCurrentSession
                        )
                      }
                    >
                      {attendanceForCurrentSession?.absence_reason?.id ? (
                        <>
                          <IconOutlinedInformation fill="#313131" />
                        </>
                      ) : (
                        <div>
                          <Icon
                            customClassName="table-attendance-student__add-reason-icon"
                            iconName="add"
                          />
                        </div>
                      )}
                    </ButtonLineal>
                  ) : valNoAdminInfoJustification && !isDisabledSessionBox ? (
                    <ButtonLineal
                      width={28}
                      hasPadding={false}
                      underline={false}
                      size="small"
                      onClick={() =>
                        showModalDetailJustification(
                          attendanceForCurrentSession
                        )
                      }
                    >
                      <IconOutlinedInformation fill="#313131" />
                    </ButtonLineal>
                  ) : null}
                </div>
              );
            }
          });
        }
      );

    setColumnsTable(columnsTableMap);
  };

  const addDateSessionsToStudents = (
    students: IStudent[],
    dateSessions: any
  ) => {
    return students.map(student => ({
      ...student,
      date_session: dateSessions // Añadimos el array dateSessions a cada estudiante
    }));
  };

  useEffect(() => {
    const studentsWithSessions = addDateSessionsToStudents(
      roomInfo.students,
      roomInfo.date_session
    );

    setTableDataSource(studentsWithSessions);
  }, [roomInfo.students, roomInfo.date_session]);

  useEffect(() => {
    pushSessionColumns(studentRecords, columnsTableMap);
  }, [
    studentRecords,
    isLoadingBulkAttendanceToday,
    isTakedBulkAttendanceToday,
    openPopover,
    cancellationRisk
  ]);

  const handleChangeSelectSession = (option: ISessionSelect) => {
    qtySessions.current = option;
    refetchAttendanceDetails(option.value === 'all' ? true : false);
  };

  const listCancellationReasons = async () => {
    try {
      const { data } = await getCancellationReasons();
      const dataConverted = data.map((reason: ICancellationReason) =>
        convertListCancellationReasons(reason)
      );
      setDataCancellationReasons(dataConverted);
    } catch (error) {
      console.log(`error fetching cancellation reasons: ${error}`);
    }
  };

  const handleSelectCancellationReason = (reason: any) => {
    setCancellationReasonSelected(reason);
    setOpenPopover({ ...openPopover, cancellationReason: reason });
  };

  const getBadgeColor = (category: string) => {
    return category
      ?.toLowerCase() // Convierte a minúsculas
      .normalize('NFD') // Descompone caracteres con tildes
      .replace(/[\u0300-\u036f]/g, '') // Elimina marcas de acentuación
      .replace(/\s+/g, '-'); // Reemplaza espacios en blanco con guiones
  };

  const handleEditCancellationReason = () => {
    if (studentRecords.length > 0) {
      closeModalUpdateCancellationReason();
      setEditCancellationReason(true);
      listCancellationReasons();
      openModalDeleteSesion();
    }
  };

  const convertListCancellationReasons = (reason: CancellationReasons) => {
    return {
      id: reason?.id,
      value: reason?.name,
      label: reason?.name,
      component: (
        <Badge
          key={`badge-${reason?.id}`}
          variant="small"
          type="quiet"
          text={reason?.category}
          status="active"
          customClassName={`modal-cancel-session__badge-color-${getBadgeColor(reason?.category)}`}
        />
      )
    };
  };

  return (
    <>
      {copied && (
        <AlertSnackbar severity="success">
          <Typography variant="body6">
            {copiedValue.slice(0, 32)} Copiado!
          </Typography>
        </AlertSnackbar>
      )}
      <div className="attendance-student-v2">
        <div>
          <div className="attendance-student-v2__content">
            <Typography
              variant="body6"
              className="attendance-student-v2__content--text"
            >
              La asistencia se guarda automáticamente al completar el campo.
              Solo ADMIN puede seleccionar las últimas 3 opciones:
            </Typography>
          </div>
          <div className="attendance-student-v2__status-tags">
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--attended"
            >
              <strong>A</strong> = Asistencia
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--delay"
            >
              <strong>T</strong> = Tardanza
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--absence"
            >
              <strong>F</strong> = Falta
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--justified"
            >
              <strong>J</strong> = Justificación
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--recovered"
            >
              <strong>R</strong> = Recuperación
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--material"
            >
              <strong>MR</strong> = Material Revisado
            </Typography>
            <Typography
              variant="body6"
              className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--justified"
            >
              <strong>N/A</strong> = No Aplica
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20
          }}
        >
          {/* {user?.permission?.name == ADMIN && (
            <RenderButtonAddSession roomInfo={roomInfo} />
          )} */}
          <Dropdown
            qtyOptions={2}
            selectedOption={qtySessions.current}
            options={[
              { value: 'partial', label: '± 3 sesiones' },
              { value: 'all', label: 'Todas las sesiones' }
            ]}
            onChange={(e: ISessionSelect) => handleChangeSelectSession(e)}
            placeholder="± 3 sesiones"
          />
        </div>
      </div>

      {tableDataSource?.length > 0 && columnsTable && (
        <Table
          columns={columnsTable}
          data={tableDataSource}
          className="table-attendance-student__table"
        />
      )}
      <Modal
        type="small"
        title="Pasar asistencia a todos los estudiantes"
        description={`Vas a poner <b>“Asistió”</b> a todos los estudiantes del curso que no tengan completada la asistencia de la sesión actual. <br /><br /> Puedes modificar la asistencia de cada estudiante luego.`}
        primaryButtonText="Confirmar"
        linkButtonText="Cancelar"
        handlePrimaryButton={() => takeTodaysBulkAttendance(selectSesion)}
        handleLinkButton={closeCancelModalBulkAttendanceStudent}
        handleClose={closeModalBulkAttendanceToday}
        isModalOpen={isOpenModalBulkAttendanceToday}
        linkButtonUnderline
        primaryButtonVariant="success"
      />
      <Modal
        type="small"
        title={`Motivo de cancelación de la sesión ${openPopover?.session}:`}
        description={
          openPopover?.cancellationReason?.label
            ? `<b>Contratiempos | </b> ${openPopover?.cancellationReason?.label} ${user?.permission.name == ADMIN ? `<br /><br /> ${openPopover?.isPaidSession ? '💵  Se ' : '❌ No se '} paga la hora del profesor` : ''}`
            : '-'
        }
        primaryButtonText={
          user?.permission.name == ADMIN ? 'Editar motivo' : 'Entendido'
        }
        handlePrimaryButton={
          user?.permission.name == ADMIN
            ? handleEditCancellationReason
            : closeModalUpdateCancellationReason
        }
        handleClose={closeModalUpdateCancellationReason}
        isModalOpen={isOpenModalUpdateCancellationReason}
        primaryButtonVariant="neutral"
        customClassName="modal-detail-cancellation-session"
      />
      <Modal
        type="big"
        isModalOpen={isOpenModalDeleteSesion}
        customClassName="modal-cancel-session"
        handleClose={closeModalDeleteSesion}
        maskClosable={false}
        bigTypeContent={
          <div className="modal-cancel-session__content">
            <div>
              <Typography variant="h4">
                ¿Seguro que quieres cancelar la sesión {openPopover?.session}?
              </Typography>
              <Typography variant="body3">
                Para hacerlo, debes indicar el motivo de cancelación. Puedes
                actualizar esta información luego.
              </Typography>
            </div>
            <div className="modal-cancel-session__dropdown">
              <Typography variant="body5">Motivo de cancelación*</Typography>
              <DropdownFilter
                qtyOptions={5}
                selectedOption={
                  editCancellationReason
                    ? openPopover?.cancellationReason
                    : undefined
                }
                options={dataCancellationReasons}
                onChange={handleSelectCancellationReason}
                placeholder="Selecciona el motivo de cancelación"
                onReset={() => {
                  setCancellationReasonSelected(null);
                }}
              />
            </div>
            <div className="modal-cancel-session__container-toggle">
              <div>
                <Typography variant="body3">
                  Se paga la hora del profesor
                </Typography>
                <Toggle
                  checked={openPopover?.isPaidSession}
                  onChange={handleChangeTogglePaidTeacher}
                />
              </div>
              <Typography variant="body6">
                (solo se modifica por el equipo de Operaciones)
              </Typography>
            </div>
            <div className="modal-cancel-session__advice">
              <Icon
                iconName="info"
                customClassName="modal-cancel-session__icon-info"
              />
              <Typography variant="body4">
                Si cancelas esta sesión, el curso tendrá{' '}
                {studentRecords && studentRecords[0]?.number_sessions - 1}{' '}
                sesiones. Puedes volver a agregar sesiones desde la
                funcionalidad de Cambio de calendario académico
              </Typography>
            </div>
            <div className="modal-cancel-session__buttons">
              <Button
                type="tertiary"
                underline={true}
                size="big"
                text="Cancelar"
                onClick={handleCloseModalDeleteSession}
              />
              <Button
                type="primary"
                primaryVariant="error"
                size="big"
                text="Confirmar"
                disabled={
                  cancellationReasonSelected?.id ||
                  hasChangeValueCancellationReason
                    ? false
                    : true
                }
                onClick={() => deleteSesion(selectSesion)}
              />
            </div>
          </div>
        }
      />
      <Modal
        customClassName="table-attendance-student__modal--active-session"
        type="small"
        title="¿Seguro que quieres habilitar esta sesión?"
        description={`Si habilitas esta sesión, el curso tendrá ${studentRecords && studentRecords[0]?.number_sessions + 1} sesiones.`}
        primaryButtonText="Habilitar sesión"
        linkButtonText="Cancelar"
        handlePrimaryButton={() => habilitarSesion(selectSesion)}
        handleLinkButton={closeModalAddSesion}
        handleClose={closeModalAddSesion}
        isModalOpen={isOpenModalAddSesion}
        linkButtonUnderline
        primaryButtonVariant="success"
      />
      <ModalInactiveStudentsReasons
        studentName={studentName}
        studentId={studentId}
        width="lg"
        isOpen={isOpenModalAddTeacherEtario}
        closeModal={closeModalAddTeacherEtario}
        reasonOptions={reasonOptions}
        refetch={refetch}
        refetchStatusStudentsByRoom={fetchStatusStudentsByRoom}
        roomId={roomInfo?.id}
        studentState={studentState}
      />
      <ModalInactiveStudent
        width="xs"
        title={`${studentName} está inactivo por:`}
        message={inactiveStudentReason}
        isOpenModal={isOpenModalActiveStudent}
        openModal={openModalActiveStudent}
        closeModal={closeModalActiveStudent}
        button1={
          <ButtonFilled
            fullWidth={true}
            size="default"
            onClick={closeModalActiveStudent}
          >
            Aceptar
          </ButtonFilled>
        }
        button2={
          user?.permission.name == ADMIN ? (
            <ButtonLineal size="default" onClick={handleConfirm}>
              Volver a activar
            </ButtonLineal>
          ) : null
        }
      />
      <ModalInactiveStudentsReasons
        studentName={studentName}
        studentId={studentId}
        inactiveStudentReason={inactiveStudentReason}
        width="lg"
        isOpen={isOpenModalConfirmActive}
        closeModal={closeModalConfirmActive}
        reasonOptions={reasonOptions}
        refetchStatusStudentsByRoom={fetchStatusStudentsByRoom}
        refetch={refetch}
        roomId={roomInfo?.id}
        studentState={studentState}
      />
      <ModalJustification
        refetchAttendanceDetails={refetchAttendanceDetails}
        qtySessions={qtySessions}
        reasonAbsence={reasonAbsence}
        setReasonAbsence={setReasonAbsence}
        attendanceId={currentSessionId}
        student={currentStudent}
        listReason={listReasonAbsence}
        closeModal={closeModalAddJustification}
        isOpenModal={isOpenModalAddJustification}
        typeModal={typeModalJustification}
      />
      <ModalLocal
        className="modal-detail-justification"
        width="sm"
        isOpen={isOpenModalDetailJustification}
        closeModal={closeModalDetailJustification}
        title={
          <NewTypography align="center" type="h1" variation="mobile" bold>
            Justificación de la falta:
          </NewTypography>
        }
        align="center"
        body={
          <NewTypography align="center" type="paragraph1" variation="mobile">
            {reasonAbsence?.name}
          </NewTypography>
        }
        buttons={
          <ButtonFilled
            fullWidth
            size="large"
            onClick={closeModalDetailJustification}
          >
            Aceptar
          </ButtonFilled>
        }
      />
      <Modal
        centered
        customClassName="content-cancellation-risk"
        type="big"
        handlePrimaryButton={() => {}}
        handleLinkButton={() => {}}
        handleClose={closeModalCancellationRisk}
        isModalOpen={isOpenModalCancellationRisk}
        //bigTypeContent={<ContentCancellationRisk onClick={openModalDeleteSesion} />}
        closeIcon={false}
      />
    </>
  );
};

export default TableAttendanceStudentV3;
