import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import './tableStudentRooms.scss';
import Link from '../../molecules/Link';
import Table from '../../molecules/Table';
import IconArrow from '../../atoms/icons/IconArrow';
import Typography from '../../atoms/Typography/Typography';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import {
  selectStateStudents,
  listStudentHistoryRooms
} from '../../../slices/studentSlice/studentSlice';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import { selectStateStudentForId } from '../../../slices/studentSlice/studentByIdSlice';
import { TagHistoryStatus } from '../../../components/molecules/TagHistoryStatus/TagHistoryStatus';
// import { getStudentHistoryRooms } from '../../../services/students';

const TableStudentRooms = () => {
  const { studentId: currentStudentId } = useParams();

  const dispatch: AppDispatch = useAppDispatch();
  const { students, isLoading } = useSelector(selectStateStudents);
  const { student, isLoadingEditStudent } = useSelector(
    selectStateStudentForId
  );
  const [isNotEmpty, setIsNotEmpty] = useState(false);
  const [studentHistory, setStudentHistory] = useState(null);

  const customCellStatusTag = ({ cell }: any) => (
    <TagHistoryStatus status={cell.value} />
  );

  const getStudentHistoryRooms: any = async (studentId: any) => {
    try {
      const response = await dispatch(listStudentHistoryRooms(studentId));

      // const response = await getStudentHistoryRooms(studentId);

      const fakeResponse: any = [
        {
          id: 54,
          room: {
            id: 5614,
            name: 'IPE - JF17 - Videojuegos Tipo Plataforma + Diseño Web con Wix  - jueves 26 de sept, 09:00 a. m.'
          },
          state: 'En riesgo'
        },
        {
          id: 55,
          room: {
            id: 5175,
            name: 'Python 1 - martes 25 de junio, 07:00 p. m.'
          },
          state: 'Activo'
        },
        {
          id: 55,
          room: {
            id: 5175,
            name: 'Python 1 - martes 25 de junio, 07:00 p. m.'
          },
          state: 'Cambiado'
        },
        {
          id: 54,
          room: {
            id: 5614,
            name: 'IPE - JF17 - Videojuegos Tipo Plataforma + Diseño Web con Wix  - jueves 26 de sept, 09:00 a. m.'
          },
          state: 'Inactivo'
        },
        {
          id: 55,
          room: {
            id: 5175,
            name: 'Python 1 - martes 25 de junio, 07:00 p. m.'
          },
          state: 'Finalizado'
        },
        {
          id: 55,
          room: {
            id: 5175,
            name: 'Python 1 - martes 25 de junio, 07:00 p. m.'
          },
          state: 'Eliminado'
        }
      ];
      if (response?.payload) {
        setStudentHistory(response?.payload);
        // setStudentHistory(fakeResponse);
        return response?.payload?.data;
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getStudentHistoryRooms(currentStudentId);
  }, [currentStudentId]);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'room.id',
        disableSortBy: true,
        disableFilters: true,
        widthColumn: 70
      },
      {
        Header: 'Nombre de salón',
        widthColumn: 400,
        accessor: 'room.name',
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => (
          <span
            style={{
              color:
                props?.row?.values?.state === 'Cambiado' ||
                props?.row?.values?.state === 'Eliminado' ||
                props?.row?.values?.state === 'Finalizado'
                  ? '#BCBCBC'
                  : '#313131'
            }}
          >
            {props?.row?.values['room.name']}
          </span>
        )
      },
      {
        Header: 'Estado del estudiante',
        widthColumn: 150,
        accessor: 'state',
        disableSortBy: true,
        disableFilters: true,
        Cell: customCellStatusTag
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        disableFilters: true,
        tooltipValue: 'inscribir',
        Cell: (props: any) => {
          return (
            <>
              <div className="table-student-rooms__button table-student-rooms__button--hover-lineal">
                <Link
                  className="table-student-rooms__link-room"
                  to={`/dashboard/rooms/${props?.row?.values['room.id']}`}
                  iconRight={<IconArrow fill="#000000" />}
                >
                  <u>Ver salón</u>
                </Link>
              </div>
            </>
          );
        }
      }
    ],
    []
  );

  // if (isLoading || !students) return <ScreenLoader />;

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Histórico de salones
      </Typography>
      {/* {student?.rooms_by_student?.length > 0 ? ( */}
      {studentHistory ? (
        <Table
          hidePagination={true}
          data={studentHistory}
          // data={student?.rooms_by_student}
          columns={columns}
        />
      ) : (
        <Typography
          className="table-student-rooms__message"
          type="paragraph"
          variation="paragraph2"
        >
          El alumno no cuenta con salones disponibles
        </Typography>
      )}
    </>
  );
};

export default TableStudentRooms;
