import React, { useState } from 'react';
import { Button, Toggle, Typography, RadioButton } from 'ctc-component-library';

interface props {
  onClick: () => void;
  checkedToggle: boolean;
  onChangeToggle: (checked: boolean) => void;
  onChangeModality: (val: boolean) => void;
  isVisibleButton?: boolean;
  defaultCheckedItem?: string;
  showAttendanceToAll?: boolean;
  handleAttendanceToAll?: () => void;
}

export const ContentCancellationRisk = ({
  onClick,
  checkedToggle,
  onChangeToggle,
  onChangeModality,
  defaultCheckedItem,
  isVisibleButton = true,
  showAttendanceToAll = false,
  handleAttendanceToAll
}: props) => {
  const [radioButtonOptions, setRadioButtonOptions] = useState([
    { label: 'Virtual', value: 'virtual' },
    { label: 'Presencial', value: 'on_site' }
  ]);

  return (
    <div className="content-cancellation-risk__content">
      {showAttendanceToAll ? (
        <>
          <div className="content-cancellation-risk__content--container-assistance">
            <button
              className="content-cancellation-risk__content--container-assistance--button"
              onClick={handleAttendanceToAll}
            >
              <Typography
                className="content-cancellation-risk__content--container-assistance--label"
                variant="body5"
              >
                <u>Poner asistencia a todos</u>
              </Typography>
            </button>
          </div>
          <div className="horizontal-separator" />
        </>
      ) : null}
      <div className="content-cancellation-risk__content--container-risk ">
        <Typography
          className="content-cancellation-risk__content--container-risk--toogle-label"
          variant="body5"
        >
          Riesgo de cancelación
        </Typography>
        <Toggle
          size="small"
          checked={checkedToggle}
          onChange={onChangeToggle}
        />
      </div>

      {isVisibleButton ? (
        <>
          <div className="horizontal-separator" />
          <div className="content-cancellation-risk__content--container-modality">
            <Typography
              variant="body6"
              className="content-cancellation-risk__content--container-modality--title"
            >
              <strong>Modalidad de la sesión para el profesor CTC</strong>
            </Typography>
            <div>
              <div className="content-cancellation-risk__content--container-modality--radio">
                <RadioButton
                  // disabledGroup={disabledTeacherSelectForm || disabledRadioButtonGroup}
                  size="middle"
                  options={radioButtonOptions}
                  defaultCheckedItem={defaultCheckedItem}
                  onChange={(e: any) => {
                    onChangeModality(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="horizontal-separator" />
        </>
      ) : null}
      {isVisibleButton ? (
        <Button
          customBtnClassName="content-cancellation-risk__content--btn-cancel"
          type="tertiary"
          text="Cancelar sesión"
          onClick={onClick}
        />
      ) : null}
    </div>
  );
};
