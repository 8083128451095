import TagStatus from '../../atoms/TagStatus/Tag';

import './TagHistoryStatus.scss';

interface Props {
  status: string;
}

export const TagHistoryStatus: React.FC<Props> = ({ status }) => {
  const replaceSpacesWithDash = (text: string): string => {
    return text.split(' ').join('-');
  };

  return (
    <TagStatus
      className={`tag-history-status tag-history-status--${replaceSpacesWithDash(status)}`}
    >
      {status}
    </TagStatus>
  );
};
