import { useState } from 'react';
import { Stack } from '@mui/material';

import {
  addStudentToRoom,
  listRoomsByGroupId
} from '../../../../../slices/roomSlice/roomsByGroupIdSlice';
import SelectComp from '../../../../../components/molecules/SelectComp';
import { AppDispatch, useAppDispatch } from '../../../../../store/store';
import { IRoom, IStudentRoom } from '../../../../../interfaces/group.interface';

interface Props {
  studentId: number;
  groupId: number;
  rooms: IRoom[];
  room: IStudentRoom;
  refetch?: () => void;
}

const UpdateStudentRoom: React.FC<Props> = ({
  studentId,
  groupId,
  rooms,
  room
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [selectedRoom, setSelectedRoom] = useState<any>(room);

  const handleUpdateStudentRoom = async ({
    id,
    name
  }: {
    id: number | string;
    name: string;
  }) => {
    const roomId = Number(id);
    const old_room = selectedRoom.id;
    setSelectedRoom({ roomId, name });

    await dispatch(addStudentToRoom({ roomId, studentId, old_room }));
    await dispatch(listRoomsByGroupId(Number(groupId)));
  };

  return (
    <Stack
      sx={{ width: '100%' }}
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SelectComp
        size="small"
        width="270px"
        onclickDefaultValue={handleUpdateStudentRoom}
        options={rooms}
        dividerItems
        value={selectedRoom}
        disabled={rooms.length > 0 ? false : true}
        placeholder="Ningún salón"
        firstItemMenu={{ id: 0, name: 'Ningún salón' }}
        showId={true}
      />
    </Stack>
  );
};

export default UpdateStudentRoom;
