// Axios
import axios from 'axios';

// Helpers
import { getAccessCookie } from '../helpers/cookies.helpers';
import { EmailAndPassword, IStudent } from '../interfaces/students.interface';

//Constants
import { FILTERS_TO_QUERY_PARAMS_TRANSLATION } from '../constants/studentList.constants';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? STUDENTS
export const getStudents = async (
  currentPage: number,
  filters: object | null
) => {
  const params = new URLSearchParams();

  params.append('page', currentPage.toString());

  if (filters != null) {
    for (const [key, value] of Object.entries(filters)) {
      const keyToAdd = FILTERS_TO_QUERY_PARAMS_TRANSLATION.get(key) || key;
      params.append(keyToAdd, value);
    }
  }

  const url = `${instanceBaseURL}/backoffice/v2/students?${params.toString()}`;

  const { data } = await axios.get<IStudent[]>(url, {
    headers: { Authorization: `Bearer ${getAccessCookie()}` }
  });
  return data;
};

export const getStatusStudentsByRoom = async (roomId: number) => {
  const { data } = await axios.get(
    `${instanceBaseURL}/v1/room/${roomId}/status-student/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getStudentForId = async (studentId: number) => {
  const { data } = await axios.get<IStudent>(
    `${instanceBaseURL}/students/${studentId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postAddStudentToGroup = async (
  groupId: number,
  selectedStudentId: number,
  projectId?: number
) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/groups/${groupId}/students/${selectedStudentId}/`,
    { project_id: projectId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const patchUpdateStudentProjectId = async (
  groupId: number,
  studentId: number,
  projectId?: number
) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/groups/${groupId}/students/${studentId}/assign-project/`,
    { project_id: projectId },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postUpdateStudentRoom = async (
  roomId: any,
  studentId: any,
  old_room: number | null
) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/rooms/${roomId}/students/${studentId}/`,
    { old_room },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};
//credenciales
export const patchUpdateStudent = async (
  { email, password, password_google }: EmailAndPassword,
  studentId: any
) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/students/${studentId}/`,
    { email, password, password_google },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const patchEditStudent = async (student: any, studentId: any) => {
  const { data } = await axios.patch(
    `${instanceBaseURL}/students/${studentId}/`,
    student,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const removeStudentOfGroup = async (groupId: any, studentId: any) => {
  const { data } = await axios.delete(
    `${instanceBaseURL}/groups/${groupId}/students/${studentId}`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const postCreateStudent = async (student: any) => {
  const { data } = await axios.post(
    `${instanceBaseURL}/students-register-backoffice/`,
    student,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const syncStudentWithMoodle = async (studentId: number) => {
  const response = await axios.get<any>(
    `${instanceBaseURL}/backoffice/moodle-sync/student/${studentId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const syncStudentPlatforms = async () => {
  const response = await axios.get<any>(
    `${instanceBaseURL}/backoffice/bulk-sync-students/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getInactiveStudentReasons = async () => {
  const response = await axios.get<any>(
    `${instanceBaseURL}/backoffice/reasons-student-inactive/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchChangeEnrollmentState = async (student: any) => {
  const data = await axios.post(
    `${instanceBaseURL}/backoffice/student/change-enrolment-state/`,
    student,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const deleteStudent = async (studentId: number) => {
  const response = await axios.delete<any>(
    `${instanceBaseURL}/backoffice/students/delete/${studentId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const getAvailableMoodleRooms = async (id: number) => {
  const response = await axios.get<any>(
    `${instanceBaseURL}/rooms/${id}/same-template-moodle/available/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return response;
};

export const patchTransferStudentToRoom = async (idRoomToMove: any, idCurrentRoom: any, idStudent: any) => {
  const current_room_id = idCurrentRoom;
  const transfer_room_id = idRoomToMove;
  const student_id = idStudent;

  const data = await axios.patch(
    `${instanceBaseURL}/rooms/transfer/student/`,
    { current_room_id, transfer_room_id, student_id },
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};

export const getStudentHistoryRooms = async (studentId: number) => {
  const { data } = await axios.get(
    `${instanceBaseURL}/history/v1/rooms/student/${studentId}/`,
    { headers: { Authorization: `Bearer ${getAccessCookie()}` } }
  );
  return data;
};