import React from 'react';
import { Icon, Checkbox, Typography } from 'ctc-component-library';

import './ContentNewsModal.scss';

export const ContentNewsModal: React.FC<{
  checked: any;
  setChecked: any;
}> = ({ checked, setChecked }) => {
  return (
    <div className="session-info">
      <div className="session-info__list">
        <div className="session-info__list--container">
          <div className="session-info__list--container-icon session-info__list--container-icon--virtual">
            <Icon
              customClassName="session-info__list--container-icon--icon"
              iconName="laptop_windows"
            />
          </div>
          <Typography variant="body3">
            Si la clase es virtual, verás el ícono de una laptop.
          </Typography>
        </div>
        <div className="session-info__list--container">
          <div className="session-info__list--container-icon session-info__list--container-icon--on-site">
            <Icon
              customClassName="session-info__list--container-icon--icon"
              iconName="location_on"
            />
          </div>
          <Typography variant="body3">
            Si la clase es presencial, verás el ícono de una ubicación.
          </Typography>
        </div>
      </div>
      <div className="session-info__checkbox">
        <Checkbox
          customClassName="session-info__checkbox--input"
          id="item-update"
          checked={checked}
          onChange={() => setChecked(!checked)}
        >
          No volver a mostrar este mensaje
        </Checkbox>
      </div>
    </div>
  );
};
