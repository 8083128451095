import React, { useState } from 'react';
import { Button, Typography, DropdownFilter } from 'ctc-component-library';

import './ChangeRoomModal.scss';

interface ChangeRoomModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (e: string) => void;
  studentName: string;
  availableRooms: { id?: string; label: string; value: string }[];
}

export const ChangeRoomModal: React.FC<ChangeRoomModal> = ({
  isOpen,
  onClose,
  onConfirm,
  studentName,
  availableRooms
}) => {
  const [selectedRoom, setSelectedRoom] = useState<any>();

  const handleConfirm = () => {
    onConfirm(selectedRoom);
  };

  if (!isOpen) return null;

  return (
    <div className="change-room-modal">
      <Typography className="change-room-modal__title" variant="h4">
        Cambiar estudiante de salón
      </Typography>
      <Typography className="change-room-modal__description" variant="body4">
        Podrás cambiar a <strong>{studentName}</strong> a otro salón que tenga
        el mismo template padre en Moodle.
      </Typography>

      <div className="change-room-modal__form-group">
        <Typography
          className="change-room-modal__form-group-label"
          variant="body5"
        >
          Salones disponibles*
        </Typography>
        <DropdownFilter
          key={`${studentName}`}
          customClassName={`change-room-modal__form-group-dropdown`}
          options={availableRooms ? availableRooms : []}
          onChange={(e: any) => {
            console.log('********** DropdownFilter onChange')
            setSelectedRoom(e?.id);
          }}
          placeholder="Seleccionar salón"
          onReset={() => {
            console.log('********** DropdownFilter onReset')
            setSelectedRoom(null);
          }}
        />
      </div>

      <div className="change-room-modal__button-group">
        <Button
          customBtnClassName="change-room-modal__button-group-cancel"
          type="secondary"
          text="Volver"
          onClick={() => {
            setSelectedRoom(null); // Reset the selected room
            onClose(); // Close the modal
          }}
          size="big"
        />
        <Button
          disabled={!selectedRoom}
          customBtnClassName="change-room-modal__button-group-confirm"
          type="primary"
          primaryVariant="neutral"
          text="Confirmar"
          onClick={handleConfirm}
          size="big"
        />
      </div>
    </div>
  );
};
