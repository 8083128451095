import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getSessionLink } from '../../../services/rooms';
import Redirect from '../../../components/organism/redirect/Redirect';
import { IRedirectSession } from '../../../interfaces/room.interface';

const dataSessionRedirect = {
  active: {
    iconName: 'loader',
    title: 'Redirigiéndote a la clase en Zoom...',
    description:
      'Por favor, espera un momento mientras te conectamos a tu sesión.',
    note: null
  },
  failed: {
    iconName: 'signal_disconnected',
    title: 'Acceso temporalmente no disponible',
    description:
      'Estamos teniendo problemas técnicos que impiden el acceso a la clase en este momento. Nuestro equipo está trabajando para solucionar el inconveniente lo más pronto posible.',
    note: 'Contacta al equipo de CTC para recibir instrucciones'
  },
  finished: {
    iconName: 'sentiment_satisfied',
    title: 'La clase de hoy ha finalizado',
    description: null,
    note: '¡Nos vemos la próxima!'
  },
  no_session: {
    iconName: 'release_alert',
    title: 'No hay clases programadas para hoy',
    description: null,
    note: '¡Nos vemos la próxima!'
  },
  cancelled: {
    iconName: 'event_busy',
    title: 'La clase de hoy fue cancelada',
    description: null,
    note: '¡Nos vemos en la próxima sesión!'
  },
  on_site_session: {
    iconName: 'location_on',
    title: 'La sesión de hoy es presencial',
    description: null,
    note: '¡Nos vemos en clase!'
  }
} as const;

const RedirectSessionZoom = () => {
  const location = useLocation();

  type SessionType = keyof typeof dataSessionRedirect;
  const [type, setType] = useState<SessionType>();
  const [error, setError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSession, setDataSession] = useState<IRedirectSession>();
  const queryParams = new URLSearchParams(location.search);
  const idRoom = queryParams.get('room') ?? '';
  const userType = queryParams.get('type') ?? '';

  useEffect(() => {
    if (idRoom && userType) {
      getLinkSession();
    }
  }, [idRoom, userType]);

  const getLinkSession = async () => {
    setIsLoading(true);
    try {
      const response = await getSessionLink({ idRoom, userType });

      if (response.status === 200) {
        setIsLoading(false);
        setType(response.data.status);
        if (response.data.status === 'active') {
          setTimeout(() => {
            window.location.href = response.data.link;
          }, 2000);
        }
        setDataSession(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      const error: AxiosError = err as AxiosError;
      setError(true);
      if (error) {
        setType('failed');
      }
    }
  };

  const formatDateToDDMMYYYY = (dateString: string) => {
    // format from YYYY-MM-DD to DD-MM-YYYY
    const [year, month, day] = dateString.split('-');

    return `${day}-${month}-${year}`;
  };

  if (!type || !dataSession || !dataSession?.status) return <span></span>;

  if (!error) {
    return (
      <Redirect
        iconName={dataSessionRedirect[type as SessionType].iconName}
        title={dataSessionRedirect[type as SessionType].title}
        description={
          type === 'no_session' || type === 'cancelled' || type === 'finished'
            ? `La próxima sesión activa es: ${formatDateToDDMMYYYY(dataSession?.date)}`
            : dataSessionRedirect[type as SessionType].description
        }
        note={dataSessionRedirect[type as SessionType].note}
      />
    );
  } else {
    return (
      <Redirect
        iconName={dataSessionRedirect.failed.iconName}
        title={dataSessionRedirect.failed.title}
        description={dataSessionRedirect.failed.description}
        note={dataSessionRedirect.failed.note}
      />
    );
  }
};

export default RedirectSessionZoom;
