/* eslint-disable max-lines */
/* eslint-disable complexity */
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import React, { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Icon,
  Badge,
  Button,
  Dropdown,
  TimePicker,
  Typography,
  Datepicker,
  RadioButton,
  InputDuration,
  DropdownFilter
} from 'ctc-component-library';

import './ScheduleChange.scss';
import {
  changeSessionSchedule,
  checkTeacherAvailability
} from '../../../../../slices/teacherSlice/teacherSlice';
import { ScheduleChangeSchema } from './validation.schema';
import { AppDispatch, useAppDispatch } from '../../../../../store/store';
import useRoomHeaderDetails from '../../../../../hooks/useRoomHeaderDetails';
import DefaultLayout from '../../../../../components/templates/DefaultLayout';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';

export const ScheduleChange: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { roomId } = useParams();

  const initValues = {
    perioricity: null,
    perWeekSelectedDay1: '',
    perWeekSelectedInitHour1: '',
    perWeekSelectedDuration1: 0,
    perWeekSelectedDay2: '',
    perWeekSelectedInitHour2: '',
    perWeekSelectedDuration2: 0,
    perWeekSelectedDay3: '',
    perWeekSelectedInitHour3: '',
    perWeekSelectedDuration3: 0,
    perWeekSelectedDay4: '',
    perWeekSelectedInitHour4: '',
    perWeekSelectedDuration4: 0,
    perWeekSelectedDay5: '',
    perWeekSelectedInitHour5: '',
    perWeekSelectedDuration5: 0,
    perWeekSelectedDay6: '',
    perWeekSelectedInitHour6: '',
    perWeekSelectedDuration6: 0,
    initDate: '',
    endDate: '',
    teacherId: '',
    assignAsMainTeacher: null
  };

  const options = useMemo(
    () => [
      { value: '1', label: '1 vez por semana' },
      { value: '2', label: '2 veces por semana' },
      { value: '3', label: '3 veces por semana' },
      { value: '4', label: '4 veces por semana' },
      { value: '5', label: '5 veces por semana' },
      { value: '6', label: '6 veces por semana' }
    ],
    []
  );

  const optionsDays = useMemo(
    () => [
      { value: 'Monday', label: 'Lunes' },
      { value: 'Tuesday', label: 'Martes' },
      { value: 'Wednesday', label: 'Miércoles' },
      { value: 'Thursday', label: 'Jueves' },
      { value: 'Friday', label: 'Viernes' },
      { value: 'Saturday', label: 'Sábado' }
    ],
    []
  );

  const radioButtonOptions = useMemo(
    () => [
      { value: 'false', label: 'No' },
      { value: 'true', label: 'Si' }
    ],
    []
  );

  const [frequency, setFrequency] = useState<number>(1);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<
    ({
      value: string;
      label: string;
      disabled?: boolean;
    } | null)[]
  >([null, null, null, null, null, null]);
  const [initialValues, setInitialValues] = useState<any>(initValues);
  const [teachers, setTeachers] = useState<any>([]);
  const [initErrorDate, setInitErrorDate] = useState<any>(null);
  const [endErrorDate, setEndErrorDate] = useState<any>(null);
  const [conflictErrorDate, setConflictErrorDate] = useState<any>(null);
  const [duplicateKeysError, setDuplicateKeysError] = useState<any>(false);
  const [mainTeacherId, setMainTeacherId] = useState<any>(null);
  const [disabledRadioButtonGroup, setDisabledRadioButtonGroup] =
    useState<any>(false);
  const [disabledButtonSearchTeacher, setDisabledButtonSearchTeacher] =
    useState<any>(true);

  const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  };

  const parseDate = (date: any) => {
    const [day, month, year] = date.split('/'); // Separar día, mes y año
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const cleanObject = (data: any) => {
    return Object.entries(data)
      .filter(([_, value]) => value !== '' && value !== 0)
      .reduce((acc: any, [key, value]: any) => {
        acc[key] = value;
        return acc;
      }, {});
  };

  const parseDays = (data: any) => {
    return Object.keys(data)
      .filter((key: any) => key.startsWith('perWeekSelectedDay'))
      .map((key: any) => {
        const index = key.match(/\d+$/)[0]; // Extrae el índice del key (1, 2, etc.)
        return {
          name: capitalizeFirstLetter(data[`perWeekSelectedDay${index}`]),
          start_time: `${data[`perWeekSelectedInitHour${index}`]?.hour}:${data[`perWeekSelectedInitHour${index}`]?.minutes}`,
          duration: parseInt(data[`perWeekSelectedDuration${index}`], 10)
        };
      });
  };

  type Day = {
    name: string;
    start_time: string;
    duration: number;
  };

  type Schedule = {
    start_date: string;
    days: Day[];
    end_date: string;
    room_id: string;
  };

  const { data: dataRoomHeader, isLoading: isLoadingHeader } =
    useRoomHeaderDetails({
      roomId: Number(roomId),
      shouldRefetch: shouldRefetch
    });

  const hasDuplicateDays = (schedule: Schedule): boolean => {
    const dayCounts = schedule.days
      .map(day => day.name) // Extrae el nombre de cada día
      .reduce((acc: Record<string, number>, day) => {
        acc[day] = (acc[day] || 0) + 1; // Cuenta las ocurrencias de cada día
        return acc;
      }, {});

    return Object.values(dayCounts).some(count => count > 1); // Devuelve true si algún día tiene más de una ocurrencia
  };

  const updateErrorDateState = (errors: any) => {
    if (errors.start_date) {
      setInitErrorDate(errors.start_date);
    }
    if (errors.end_date) {
      setEndErrorDate(errors.end_date);
    }

    if (errors.message) {
      setConflictErrorDate(errors.message);
    }
  };

  const handleFrequencyChange = (value: string) => {
    const newFrequency = parseInt(value);
    setFrequency(newFrequency);
    // setSelectedDays(Array(newFrequency).fill(''));
  };

  const resetFrequencyFields = (frequency: any, setFieldValue: any) => {
    // Array con los nombres base de los campos
    const fieldNames = ['Day', 'InitHour', 'Duration'];

    // Generar un array con índices desde el valor de frequency + 1 hasta 6
    const indicesToReset = Array.from(
      { length: 6 - frequency },
      (_, i) => Number(i) + Number(frequency) + Number(1)
    );

    // Iterar sobre cada índice y cada campo base para resetear los valores
    indicesToReset.forEach(index => {
      fieldNames.forEach(field => {
        const fieldName = `perWeekSelected${field}${index}`;
        const resetValue = field === 'Duration' ? 0 : ''; // 0 para Duración, '' para los demás
        setFieldValue(fieldName, resetValue);
      });
    });
  };

  const goToRoom = () => {
    navigate(`/dashboard/rooms/${roomId}`);
  };

  const goToLoader = () => {
    navigate(`/dashboard/rooms/schedule/${roomId}/loader`);
  };

  const isStartDateBeforeEndDate = (data: any) => {
    const { start_date, end_date } = data;
    return (
      dayjs(start_date).isBefore(dayjs(end_date)) ||
      dayjs(start_date).isSame(dayjs(end_date))
    );
  };

  const getTeacherAvailability = async (payload: any) => {
    setInitErrorDate(null);
    setEndErrorDate(null);
    setConflictErrorDate(null);
    const validateDates = isStartDateBeforeEndDate(payload);

    if (!validateDates) {
      setConflictErrorDate('La fecha final debe ser posterior a la de inicio');
      return;
    }

    const response: any = await dispatch(checkTeacherAvailability(payload));

    if (response?.error?.message) {
      updateErrorDateState(response.payload);
      return null;
    }
    return response.payload.data;
  };

  const formatTeachersOptions = (teachers: any) =>
    teachers?.map((teacher: any) => {
      if (teacher?.main_teacher) {
        setMainTeacherId(teacher?.id);
      }
      return {
        value: teacher?.id,
        label: teacher?.full_name,
        id: teacher?.id,
        mainTeacher: teacher?.main_teacher,
        ...(teacher.main_teacher && {
          component: (
            <Badge variant="small" type="quiet" text="Titular" status="new" />
          )
        })
      };
    });

  const handleGetTeacherAvailability = async (data: any) => {
    const payload: any = {
      start_date: parseDate(data.initDate),
      days: parseDays(cleanObject(data)),
      end_date: parseDate(data.endDate),
      room_id: roomId
    };

    const hasDuplicateDaysRes = hasDuplicateDays(payload);

    setDisabledButtonSearchTeacher(true);

    if (hasDuplicateDaysRes) {
      setDuplicateKeysError(hasDuplicateDays(payload));
      return;
    }

    const teacherAvailability = formatTeachersOptions(
      await getTeacherAvailability(payload)
    );

    if (teacherAvailability) {
      setTeachers(teacherAvailability);
    }
    return;
  };

  const handleChangeSessionSchedule = async (data: any) => {
    try {
      const payload = {
        start_date: parseDate(data.initDate),
        days: parseDays(cleanObject(data)),
        end_date: parseDate(data.endDate),
        teacher_id: data.teacherId,
        assign_as_main_teacher: disabledRadioButtonGroup
          ? false
          : data.assignAsMainTeacher
        // only_validation_data: true
      };

      const response: any = await dispatch(
        changeSessionSchedule({ roomId: roomId, payload })
      );

      if (response?.error?.message) {
        updateErrorDateState(response.payload);
      }

      if (!response?.error?.message) {
        setShouldRefetch(true);
        goToLoader();
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const truncateOptions = (frequency: number, options: any) => {
    const res = options.map((option: any, index: any) => {
      return index < frequency ? option : null;
    });
    setSelectedOptions(res);
    return;
  };

  const handleDurationChangeFromFrequency = (frequency: number) => {
    const newSelectedOptions = selectedOptions.slice(0, -frequency);
    setSelectedOptions(newSelectedOptions);
  };

  const handleDropdownChange = (
    index: number,
    value: {
      value: string;
      label: string;
      disabled?: boolean;
    }
  ) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);
  };

  const getDataForDropdown = (index: number) => {
    const result = optionsDays.map(option => ({
      ...option,
      disabled: selectedOptions.some(
        (selectedOption, selectedIndex) =>
          selectedOption?.value === option.value && selectedIndex !== index
      )
    }));

    return result;
  };

  const disabledPastDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  if (isLoadingHeader || !dataRoomHeader?.id) return <ScreenLoader />;

  return (
    <DefaultLayout mainClassName="schedule-change-layout">
      <div className="schedule-change">
        <div className="schedule-change__nav">
          <Icon
            iconName="arrow_back"
            customClassName="schedule-change__nav-icon"
          />
          <button onClick={goToRoom} className="schedule-change__nav-back">
            <Typography className="" variant="body5">
              Volver al salón
            </Typography>
          </button>
        </div>
        <div className="schedule-change__header">
          <Typography className="" variant="h4">
            Cambio del calendario académico
          </Typography>

          <Typography className="" variant="body5">
            Salón:{' '}
            <strong>
              {dataRoomHeader?.name} - {dataRoomHeader?.main_teacher?.full_name}
            </strong>
          </Typography>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            setInitErrorDate(null);
            setEndErrorDate(null);
            handleChangeSessionSchedule(values);
          }}
          validationSchema={ScheduleChangeSchema}
        >
          {({ values, setFieldValue, submitForm }) => {
            const disabledDurationFormLocal = values.perioricity === null;

            const disabledPeriodFormLocal =
              values.perWeekSelectedDay1 === '' ||
              values.perWeekSelectedInitHour1 === '' ||
              values.perWeekSelectedDuration1 === 0;

            const disabledTeacherSelectLocal =
              values.initDate === '' ||
              values.endDate === '' ||
              initErrorDate ||
              endErrorDate ||
              conflictErrorDate;

            const checkDisabledPeriodFormExtraInputs = (
              frequency: any,
              values: any
            ) => {
              const day = values[`perWeekSelectedDay${frequency}`];
              const initHour = values[`perWeekSelectedInitHour${frequency}`];
              const duration = values[`perWeekSelectedDuration${frequency}`];

              return day === '' || initHour === '' || duration === 0;
            };

            const disabledPeriodFormExtraInputsLocal =
              checkDisabledPeriodFormExtraInputs(frequency, values);

            const disabledTeacherSelectForm =
              disabledPeriodFormLocal ||
              disabledDurationFormLocal ||
              disabledTeacherSelectLocal ||
              !disabledButtonSearchTeacher ||
              duplicateKeysError;

            const disabledRadioMainTeacher =
              !disabledRadioButtonGroup && values.assignAsMainTeacher === null;

            return (
              <Form>
                <section className="schedule-change__section schedule-change__section-no-border">
                  <div className="schedule-change__input-group-labels">
                    <Typography
                      className="schedule-change__input-group-labels--title"
                      variant="body3"
                    >
                      <strong>Periodicidad</strong>
                    </Typography>
                    <Typography
                      className="schedule-change__input-group-labels--description"
                      variant="body4"
                    >
                      Define la cantidad de sesiones que tendrá el salón por
                      semana.
                    </Typography>
                  </div>
                  <div className="schedule-change__input-group">
                    <Typography className="" variant="body5">
                      Periodicidad de las sesiones*
                    </Typography>
                    <Dropdown
                      customClassName="schedule-change__input-group--dropdown--perioricity"
                      options={options}
                      onChange={(e: any) => {
                        setDisabledButtonSearchTeacher(false);
                        handleFrequencyChange(e.value);
                        setDuplicateKeysError(false);
                        setInitErrorDate(null);
                        setEndErrorDate(null);
                        setConflictErrorDate(null);
                        setDisabledRadioButtonGroup(false);
                        resetFrequencyFields(e.value, setFieldValue);
                        truncateOptions(Number(e.value), selectedOptions);
                        setFieldValue('perioricity', e.value);
                      }}
                      placeholder="Seleccionar perioricidad"
                    />
                  </div>
                </section>

                <section className="schedule-change__section">
                  <div className="schedule-change__input-group-labels">
                    <Typography
                      className="schedule-change__input-group-labels--title"
                      variant="body3"
                    >
                      <strong>Días, horario y duración</strong>
                    </Typography>
                    <Typography
                      className="schedule-change__input-group-labels--description"
                      variant="body4"
                    >
                      Selecciona los días, horario y duración de las sesiones.
                    </Typography>
                  </div>

                  {/* TODO: */}
                  {Array.from({ length: frequency }).map((_, index) => (
                    <div
                      className="schedule-change__session"
                      key={`render-days-${index}`}
                    >
                      <div className="schedule-change__input-group">
                        <Typography className="" variant="body5">
                          Elegir día*
                        </Typography>
                        <Dropdown
                          disabled={disabledDurationFormLocal}
                          customClassName="schedule-change__input-group--dropdown--days"
                          options={getDataForDropdown(index)}
                          onChange={(e: any) => {
                            setDisabledButtonSearchTeacher(false);

                            setDuplicateKeysError(false);
                            setInitErrorDate(null);
                            setEndErrorDate(null);
                            setConflictErrorDate(null);
                            setDisabledRadioButtonGroup(false);
                            handleDropdownChange(index, e);
                            setFieldValue(
                              `perWeekSelectedDay${index + 1}`,
                              e.value
                            );
                          }}
                          placeholder="Seleccionar día"
                        />
                      </div>
                      <div className="schedule-change__input-group">
                        <Typography className="" variant="body5">
                          Hora de inicio (GMT-5)*
                        </Typography>
                        <TimePicker
                          disabled={disabledDurationFormLocal}
                          customClassName="schedule-change__input-group--timepicker"
                          onTimeChange={(e: any) => {
                            setDisabledButtonSearchTeacher(false);
                            setInitErrorDate(null);
                            setEndErrorDate(null);
                            setConflictErrorDate(null);
                            setDisabledRadioButtonGroup(false);
                            setFieldValue(
                              `perWeekSelectedInitHour${index + 1}`,
                              e
                            );
                          }}
                        />
                      </div>
                      <div className="schedule-change__input-group">
                        <Typography className="" variant="body5">
                          Duración en minutos*
                        </Typography>

                        <InputDuration
                          name={`perWeekSelectedDuration${index + 1}`}
                          onChange={(e: any) => {
                            setDisabledButtonSearchTeacher(false);
                            setInitErrorDate(null);
                            setEndErrorDate(null);
                            setConflictErrorDate(null);
                            setDisabledRadioButtonGroup(false);
                            setFieldValue(
                              `perWeekSelectedDuration${index + 1}`,
                              e.target.value
                            );
                          }}
                          disabled={disabledDurationFormLocal}
                        />
                      </div>
                    </div>
                  ))}
                </section>
                <section className="schedule-change__section">
                  <div className="schedule-change__input-group-labels">
                    <Typography
                      className="schedule-change__input-group-labels--title"
                      variant="body3"
                    >
                      <strong>Período de cambio</strong>
                    </Typography>
                    <Typography
                      className="schedule-change__input-group-labels--description"
                      variant="body4"
                    >
                      Define el período de días en donde se realizarán las
                      sesiones. Las fechas deben coincidir con los días
                      seleccionados arriba.
                    </Typography>
                  </div>

                  <div className="schedule-change__date-range-wrap">
                    <div className="schedule-change__date-range">
                      <div className="schedule-change__input-group">
                        <Typography className="" variant="body5">
                          Desde*
                        </Typography>

                        <span>
                          <Datepicker
                            status={
                              initErrorDate || conflictErrorDate ? 'error' : ''
                            }
                            disabled={
                              disabledPeriodFormLocal ||
                              disabledPeriodFormExtraInputsLocal
                            }
                            disabledDate={disabledPastDate}
                            onChange={(value: any, dateString: any) => {
                              setDisabledButtonSearchTeacher(false);
                              setInitErrorDate(null);
                              setConflictErrorDate(null);
                              setFieldValue('initDate', dateString);
                              setDisabledRadioButtonGroup(false);
                            }}
                          />
                          {initErrorDate ? (
                            <Typography
                              variant="body5"
                              customStyles={{ color: '#c00808' }}
                            >
                              {initErrorDate}
                            </Typography>
                          ) : null}
                        </span>
                      </div>
                      <div className="schedule-change__input-group">
                        <Typography className="" variant="body5">
                          Hasta*
                        </Typography>

                        <span>
                          <Datepicker
                            status={
                              endErrorDate || conflictErrorDate ? 'error' : ''
                            }
                            disabled={
                              disabledPeriodFormLocal ||
                              disabledPeriodFormExtraInputsLocal
                            }
                            disabledDate={disabledPastDate}
                            onChange={(value: any, dateString: any) => {
                              setDisabledButtonSearchTeacher(false);
                              setEndErrorDate(null);
                              setConflictErrorDate(null);
                              setFieldValue('endDate', dateString);
                              setDisabledRadioButtonGroup(false);
                            }}
                          />
                          {endErrorDate ? (
                            <Typography
                              variant="body5"
                              customStyles={{ color: '#c00808' }}
                            >
                              {endErrorDate}
                            </Typography>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    {conflictErrorDate ? (
                      <Typography
                        variant="body5"
                        customStyles={{ color: '#c00808' }}
                      >
                        {conflictErrorDate}
                      </Typography>
                    ) : null}
                  </div>

                  <div className="schedule-change__button-wrapper">
                    <Button
                      disabled={
                        disabledPeriodFormLocal ||
                        disabledDurationFormLocal ||
                        disabledTeacherSelectLocal ||
                        disabledButtonSearchTeacher ||
                        duplicateKeysError
                      }
                      text="Buscar profesores"
                      type="primary"
                      primaryVariant="neutral"
                      size="medium"
                      onClick={() => {
                        handleGetTeacherAvailability(values);
                        setDisabledButtonSearchTeacher(true);
                        setDisabledRadioButtonGroup(false);
                      }}
                    />
                  </div>
                </section>
                <section className="schedule-change__section">
                  <div className="schedule-change__input-group-labels">
                    <Typography
                      className={`${disabledTeacherSelectForm ? 'schedule-change__text-disabled' : ''} schedule-change__input-group-labels--title`}
                      variant="body3"
                    >
                      <strong>Profesor que tomará estas sesiones</strong>
                    </Typography>
                    <Typography
                      className={`${disabledTeacherSelectForm ? 'schedule-change__text-disabled' : ''} schedule-change__input-group-labels--description`}
                      variant="body4"
                    >
                      Si hay algún error o realizas una modificación en el
                      período del cambio o en alguno de los parámetros de la
                      sesión, deberás volver a seleccionar al profesor.
                      ¡Importante! Si el profesor titular tiene otro salón que
                      se superpone la hora, entonces no aparecerá en la lista.
                    </Typography>
                  </div>

                  <div className="schedule-change__input-group">
                    <Typography
                      className={`${disabledTeacherSelectForm ? 'schedule-change__text-disabled' : ''}`}
                      variant="body5"
                    >
                      Profesores disponibles*
                    </Typography>
                    <DropdownFilter
                      disabled={disabledTeacherSelectForm}
                      customClassName={`schedule-change__input-group--dropdown--teacher ${disabledTeacherSelectForm ? 'cursor-not-allowed' : ''}`}
                      options={teachers}
                      onChange={(e: any) => {
                        setDisabledRadioButtonGroup(false);
                        if (e.value === mainTeacherId) {
                          setDisabledRadioButtonGroup(true);
                        }
                        setFieldValue('teacherId', e.value);
                      }}
                      placeholder="Seleccionar profesor"
                      onReset={() => {
                        setFieldValue('teacherId', '');
                      }}
                    />
                  </div>

                  <div className="schedule-change__input-group">
                    <Typography
                      className={`${disabledTeacherSelectForm || disabledRadioButtonGroup ? 'schedule-change__text-disabled' : ''}`}
                      variant="body5"
                    >
                      ¿Quieres que este cambio modifique al profesor titular?*
                    </Typography>
                    <RadioButton
                      disabledGroup={
                        disabledTeacherSelectForm || disabledRadioButtonGroup
                      }
                      size="middle"
                      options={radioButtonOptions}
                      defaultCheckedItem="option2"
                      onChange={(e: any) => {
                        setFieldValue(
                          'assignAsMainTeacher',
                          e.target.value === 'true'
                        );
                      }}
                    />
                  </div>
                </section>

                <div className="schedule-change__alert">
                  <Icon iconName="info" />
                  <Typography
                    className="schedule-change__alert-description"
                    variant="body4"
                  >
                    Asegúrate de indicar correctamente si el cambio modifica o
                    no la titularidad del profesor para evitar errores en la
                    gestión de la información.
                  </Typography>
                </div>

                <div className="schedule-change__footer">
                  <div className="schedule-change__button-group-wrapper">
                    <div className="schedule-change__button-wrapper">
                      <Button
                        text="Cancelar"
                        type="secondary"
                        size="medium"
                        onClick={goToRoom}
                      />
                    </div>
                    <div className="schedule-change__button-wrapper">
                      <Button
                        disabled={
                          disabledDurationFormLocal ||
                          disabledPeriodFormLocal ||
                          disabledTeacherSelectLocal ||
                          disabledRadioMainTeacher ||
                          values.teacherId === null ||
                          values.teacherId === ''
                        }
                        text="Confirmar cambios"
                        type="primary"
                        primaryVariant="neutral"
                        size="medium"
                        onClick={submitForm}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DefaultLayout>
  );
};
