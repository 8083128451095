import axios from 'axios';

import { getAccessCookie } from '../helpers/cookies.helpers';

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

export const getCancellationReasons = async () => {
  try {
    const token = getAccessCookie();
    return await axios.get(
      `${instanceBaseURL}/catalog/reasons-session-cancellation/`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const patchRiskCancellation = async (
  sessionId: number,
  riskCancellation: boolean
) => {
  try {
    const token = getAccessCookie();
    return await axios.patch(
      `${instanceBaseURL}/rooms/session/${sessionId}/risk-cancellation/`,
      { risk_cancellation: riskCancellation },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};

export const patchModality = async (
  sessionId: number,
  modality: boolean
) => {
  try {
    const token = getAccessCookie();
    return await axios.patch(
      `${instanceBaseURL}/rooms/change-modality/session/${sessionId}/`,
      { modality: modality },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (e) {
    throw new Error(`Error: ${e}`);
  }
};
